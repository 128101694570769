@import 'styles/mixings';

.container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  @include media-breakpoint-only(lg) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }

  @include media-breakpoint-only(md) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }
}

.wrapper {
  flex: 1;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  transition: background 200ms ease;

  @include media-breakpoint-only(xs) {
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.containerSelectList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  text-transform: capitalize;
}
