@import '../../styles/mixings.scss';

.statusContainer {
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  width: 100%;

  @include media-breakpoint-only(xs) {
    margin-top: 10px;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  height: 12px;
  margin-right: 10px;
  width: 12px;
}

.dottedUnderline {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color);
  width: fit-content;
}

.alternativeBullet {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin-left: 5px;
  align-self: flex-start;
}

.status {
  flex: 1;
}

.statusStored {
  background: var(--status-stored);
}

.statusDraft {
  background: var(--status-draft);
}

.statusTerminated {
  background: var(--status-terminated);
}

.statusExecuted {
  background: var(--status-executed);
}

.statusExecution {
  background: var(--status-execution);
}

.statusPending {
  background: var(--status-pending);
}

.tooltipText {
  text-transform: none;
  font-style: italic;
}
