@import '../../styles/mixings.scss';

.tabsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  @include media-breakpoint-only(xs) {
    display: block;
  }
}

.tabsTitles {
  border-bottom: 1px solid var(--tabs-border-color-inactive);
  display: flex;
  min-height: 50px;
  margin-bottom: 30px;
  position: relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 30px;
    margin-bottom: 30px;
    margin-top: 10px;
    margin-right: -30px;
    overflow-x: auto;
    width: 100%;

    &.removeOutPageScrollMobile {
      margin-right: 0;
    }
  }
}

.tabTitlesScroll {
  display: flex;

  @include media-breakpoint-only(xs) {
    height: 100%;
    position: absolute;
  }
}

.tabTitle {
  align-items: center;
  border-bottom: 1px solid;
  border-color: transparent;
  cursor: pointer;
  color: var(--tabs-color-active);
  display: flex;
  margin-right: 35px;
  transform: translateY(1px);
  transition: opacity 200ms ease;
  outline: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    transition: opacity 200ms ease;
  }

  &.activeTabTitle {
    border-color: var(--tabs-color-active);
    opacity: 1;
    transition: opacity 200ms ease;
  }

  &.tabTitleError {
    padding-left: 20px;
  }

  @include media-breakpoint-only(xs) {
    display: block;
    transform: none;
    white-space: nowrap;
    z-index: 10;
    margin-right: 10px;

    &:hover {
      opacity: 0.5;
    }

    &.activeTabTitle {
      opacity: 1;
      transition: opacity 200ms ease;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.tabsPanel {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.tab {
  height: 100vh;
  overflow: hidden;
  left: -100vw;
  opacity: 0;
  padding-left: 1px;
  padding-right: 1px;
  position: absolute;
  top: -100vh;
  transition: opacity 200ms ease;
  width: 100%;

  &.tabVisible {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    left: 0;
    opacity: 1;
    overflow: inherit;
    top: 0;
    position: relative;
    transition: opacity 200ms ease;

    @include media-breakpoint-only(xs) {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }
}

.error {
  background: var(--navbar-error);
  color: var(--main-text-color);
  position: absolute;
  top: 4px;
  left: -6px;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}
