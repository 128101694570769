@import 'styles/mixings.scss';

.valueCurrencyContainer {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 10px;

  @include media-breakpoint-only(md) {
    grid-template-columns: 55% 45%;
  }

  @include media-breakpoint-only(sm) {
    grid-template-columns: 55% 45%;
  }

  @include media-breakpoint-only(xs) {
    grid-area: auto / span 2;
    grid-template-columns: 1fr;
    grid-gap: 13px;

    @include media-breakpoint-only(xs) {
      grid-area: auto;
    }

    input {
      border-radius: 100px;
      border: 1px solid var(--inputs-border-color);
    }
  }
}

.error {
  color: var(--signin-error-color);
  font-size: 12px;
  padding-left: 20px;
  padding-top: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
}
