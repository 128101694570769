@import 'styles/mixings';

.container {
  display: flex;
  flex-direction: column;
}

.containerSelect {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-gap: 20px;
}

.centerIcon {
  align-items: center;
}

.buttonAdd {
  display: flex;
  align-items: flex-end;
}

.containerTransactionsLiteral {
  margin-top: 20px;
}

.containerLiteralModal {
  text-align: left;
}

.containerLiteral {
  margin-top: 20px;
}

.statusContainer {
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  width: 100%;
  min-width: 80px;
  margin-top: 12px;
  margin-bottom: 6px;

  @include media-breakpoint-only(xs) {
    margin-top: 5px;
  }
}

.pending {
  color: var(--dynamicformsubtitle-color);
}

.titleLiteralStatus {
  color: var(--main-text-color-alt);
  font-size: 14px;
}

.bullet {
  border-radius: 100px;
  display: block;
  height: 12px;
  margin-right: 10px;
  width: 12px;
}

.containerStatus {
  border-bottom: 1px solid var(--settlor-display-border-color);
  margin-top: 20px;
}

.statusAbstain {
  background: var(--status-voting);
}

.statusRejected {
  background: var(--status-cancel);
}

.statusPositive {
  background: var(--status-resolved);
}
