@import '../../styles/mixings.scss';

.row {
  display: grid;
  position: relative;
  padding: 20px 32px;
  grid-template-columns: 2fr 1fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
  grid-template-areas:
    'name value withdraw actions'
    'type value withdraw actions';
  width: 100%;

  &.noName {
    grid-template-areas:
      'type value withdraw actions'
      'type value withdraw actions';
  }

  @include media-breakpoint-only(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'name'
      'type'
      'value'
      'withdraw'
      'actions';
    grid-row-gap: 5px;
    padding: 20px;
  }
}

.name {
  font-family: 'Merriweather', serif;
  font-size: 19px;
  grid-area: name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  align-items: flex-start;
  color: var(--tables-title-color);
  display: flex;
  font-size: 14px;
  grid-area: type;
  line-height: 14px;

  .typeIcon {
    margin-right: 5px;
  }
}

.error {
  background: var(--navbar-error);
  color: var(--main-text-color);
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}

.editButton {
  color: white;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--button-bold-outline);

  &Selected {
    background: var(--button-bold-background);
    color: white;
  }
}

.valuation {
  grid-area: value;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.amountInput {
  grid-area: withdraw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.amount {
  font-family: var(--subtitleFont);
  font-weight: bold;
  font-size: 12px;
}

.currency {
  font-family: var(--subtitleFont);
  font-size: 12px;
}

.nextButton {
  margin-left: 20px;
}
