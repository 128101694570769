@import 'styles/mixings';

.subtitle {
  display: flex;
  text-decoration: none;
  font-family: var(--subtitleFont);
  order: -1;
  color: var(--main-color);
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
  padding: 0;
  min-width: 250px;
  max-width: 320px;

  @include media-breakpoint-only(xs) {
    margin-left: 45px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 6px;
    order: 0;
  }
}

.errorMessageModal {
  color: var(--signin-error-color);
}
