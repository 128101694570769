@import 'styles/mixings.scss';

.buttonsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-end;
    bottom: 0;
    height: 50px;
    justify-content: center;
    position: relative;
    right: 0;
    top: 30px;
  }
}

.backButtonContainer {
  position: relative;
}
