.itemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
  margin-right: 7px;
}
