@import 'styles/mixings';

.userTableItemStructureName {
  align-items: flex-start;
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: structureName;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
}

.userTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
}

.userTableItemStatus {
  grid-area: status;
}

.userTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  grid-template: repeat(3, 1fr) / 0.8fr 1fr 100px;
  grid-template-areas:
    'itemName actions status'
    'structureName actions status'
    'updatedAt actions status';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;

    grid-template-areas:
      'itemName'
      'structureName'
      'updatedAt'
      'status';
  }
}

.userTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: repeat(2, 1fr) / 1fr;
  grid-template-areas:
    'itemName'
    'structureName'
    'updatedAt'
    'status';
  padding: 30px;

  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  .userTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  & .userTableItemStatus {
    margin-top: 10px;
  }
}

.userTableItem--list {
  grid-template-columns: 0.6fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
  
  & .userTableItemStatus {
    width: 100px;
  }

  .userTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px;
  }
}

.userTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 1fr 1fr 1fr 1fr 130px;
  grid-template-rows: 1fr;
  grid-template-areas: 'itemName structureName createdAt updatedAt status';
  padding: 20px 10px;

  & .userTableItemName,
  & .userTableItemCreatedAt,
  & .userTableItemUpdatedAt,
  & .userTableItemSettlorName {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  .userTableItemCreatedAt {
    display: flex;
  }

  .typeIcon {
    position: relative;
    top: 3px;
  }
}
