.container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.templateDownload {
  margin-bottom: 15px;
  font-size: 20px;
}

.fileUpload {
  width: 100%;
  margin-top: 15px;
}

.tipsList {
  padding-left: 15px;
}
