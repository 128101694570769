.listContainer {
  width: 100%;
}

.itemContainer {
  display: grid;
  grid-template-columns: 0.05fr 1fr;
  margin-bottom: 10px;
  min-height: 100px;
}

.decoration {
  position: relative;
}

.line {
  content: ' ';
  border-left: 1px solid var(--decoration-secondary);
  position: relative;
  left: 21px;
  top: 40px;
  height: 90%;
  width: 100%;
}

.primaryDot {
  width: 8px;
  height: 8px;
  background: var(--decoration-primary);
  content: ' ';
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 26px;
  left: 17px;
}

.secondaryDot {
  width: 20px;
  height: 20px;
  content: ' ';
  background: var(--decoration-secondary);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 11px;
}

.triangle {
  height: 10px;
  width: 20px;
  border-bottom: 10px solid var(--element-background);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 25px;
  right: -5px;
  transform: rotate(-90deg);
}

.card {
  height: 100%;
  background: var(--element-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 25px;

  &:hover {
    background: var(--element-background-hover);
  }
}
.authorStamp {
  color: var(--main-text-color-alt);
  margin-bottom: 10px;
}
