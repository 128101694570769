@import '../../styles/variables.scss';
@import '../../styles/mixings.scss';

.searchContainer {
  position: relative;

  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.searchInput {
  background: var(--element-background-odd);
  border: 0;
  border-radius: 50px;
  color: var(--main-text-color);
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 0 20px 0 50px;
  transition: all 200ms ease;
  width: 175px;

  &:focus,
  &.expanded {
    width: 500px;
  }

  @include media-breakpoint-only(md) {
    &:focus,
    &.expanded {
      width: 400px;
    }
  }

  @include media-breakpoint-only(sm) {
    &:focus,
    &.expanded {
      width: 100%;
    }
  }

  @include media-breakpoint-only(xs) {
    width: 100%;

    &:focus,
    &.expanded {
      width: 100%;
    }
  }

  &::placeholder {
    color: var(--inputs-placeholder-color);
    font-weight: normal;
    transition: all 200ms ease;
  }

  .showClose & {
    padding-right: 50px;
  }
}

.searchIcon {
  border-radius: 50px 0 0 50px;
  height: 40px;
  left: 15px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  & svg {
    width: 25px;
    height: 25px;
  }
}

.resetIcon {
  border-radius: 50px 0 0 50px;
  height: 40px;
  right: 15px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease-in-out;

  & svg {
    width: 25px;
    height: 25px;
  }

  .showClose & {
    visibility: visible;
    opacity: 1;
  }
}
