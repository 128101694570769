@import '../../styles/mixings.scss';

/*.loading {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}*/

.pageTitleContainer {
  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.pageTitleButtonContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-only(xs) {
    display: block;
  }
}
