@import '../../../styles/mixings.scss';

.date {
  font-size: 14px;
  color: var(--history-low-draft);

  &.cardActive {
    color: var(--main-color);
  }

  &.cardDeleted {
    color: var(--main-text-color-alt);
  }

  @include media-breakpoint-only(xs) {
    font-size: 12px;
  }
}

.dateCaps {
  text-transform: capitalize;
}
