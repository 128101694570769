.error {
  color: var(--signin-error-color);
  font-size: 12px;
  padding-left: 20px;
  padding-top: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.errorLiteral {
  padding-left: 0;
}
