.tablePager {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  margin-top: 20px;
  padding: 0;
}

.page {
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 30px;
  margin-right: 5px;
  justify-content: center;
  transition: all 200ms ease;
  width: 30px;

  &:hover:not(.active) {
    background: var(--tablepager-background-inactive);
    transition: all 200ms ease;
  }

  &.active {
    background: var(--tablepager-background-active);
    color: var(--secondary-color);
    transition: all 200ms ease;
  }
}
