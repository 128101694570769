@import 'variables';
@import 'mixings';

body {
  font-family: verdana, sans-serif;
  margin: 0;
  background: $bodyBackground;
  background-size: $bodyBackgroundSize;
  background-position: $bodyBackgroundPosition;
  background-repeat: $bodyBackgroundRepeat;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
ol,
ul,
li,
p {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

:global {
  .visibleDesktop {
    @include media-breakpoint-only(xs) {
      display: none;
    }

    @include media-breakpoint-only(sm) {
      display: none;
    }
  }

  .visibleMobile {
    @include media-breakpoint-only(lg) {
      display: none;
    }

    @include media-breakpoint-only(md) {
      display: none;
    }
  }
}
