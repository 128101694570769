@import '../../styles/mixings.scss';

.notFoundList {
  align-items: center;
  color: var(--main-text-color-alt);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  flex: 1;

  @include media-breakpoint-only(xs) {
    min-height: 100%;
  }
}

.topText {
  margin-bottom: 5px;
  margin-top: 5px;

  @include media-breakpoint-only(xs) {
    text-align: center;
  }
}

.onClick {
  cursor: pointer;
}

.button {
  margin-top: 10px;
}
