@import '../../styles/mixings.scss';

.trustDocumentArchiveItem {
  background: var(--element-background);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 22px 50px;
  position: relative;
  opacity: 1;
  transition: filter 200ms ease;
  z-index: 10;

  @include media-breakpoint-only(xs) {
    padding: 20px;
  }

  &:hover {
    filter: brightness(120%);
    transition: filter 200ms ease;
  }

  &.arrow {
    &:after {
      border: solid transparent;
      border-color: transparent;
      border-right-color: var(--element-background);
      border-width: 7px;
      content: ' ';
      height: 0;
      margin-top: -10px;
      position: absolute;
      pointer-events: none;
      right: 100%;
      top: 30px;
      width: 0;

      @include media-breakpoint-only(xs) {
        top: 27px;
      }
    }
  }

  &.child {
    margin-left: 30px;
    z-index: 9;

    @include media-breakpoint-only(xs) {
      margin-left: 15px;
    }
  }
}

.letterTop {
  display: flex;

  @include media-breakpoint-only(xs) {
    display: block;
  }
}

.itemData {
  flex: 1;
}

.title {
  font-family: 'Merriweather', serif;
  font-size: 20px;
  margin-bottom: 7px;
  margin-top: 7px;

  .cardDeleted & {
    color: var(--sub-menu-color);
  }

  @include media-breakpoint-only(xs) {
    font-size: 18px;
  }
}

.itemStatus {
  align-items: center;
  color: var(--sub-menu-color);
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  width: 110px;

  @include media-breakpoint-only(xs) {
    font-size: 11px;
    margin-top: 10px;
  }
}

.statusIndicator {
  display: block;
  border-radius: 100px;
  height: 10px;
  margin-right: 10px;
  width: 10px;
  order: -1;

  .cardActive & {
    background: var(--history-low-active);
  }

  .cardDraft & {
    background: var(--history-low-draft);
  }

  .cardDeleted &,
  .cardDeprecated & {
    background: var(--history-low-inactive);
  }

  .cardRevoked & {
    background: var(--signin-error-color);
  }

  @include media-breakpoint-only(xs) {
    height: 8px;
    width: 8px;
  }
}

.cap {
  text-transform: capitalize;
}

.loadingContent {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
}

.content {
  font-size: 14px;
  margin-top: 30px;
  white-space: pre-wrap;
  word-wrap: break-word;

  @include media-breakpoint-only(xs) {
    margin-top: 15px;
  }
}

.fileContent {
  margin-top: 30px;

  @include media-breakpoint-only(xs) {
    margin-top: 15px;
  }
}

.collapseButton {
  margin-top: 10px;
}

.buttonsContainer {
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  @include media-breakpoint-only(lg) {
    &:not(.itemCardExpanded) {
      bottom: 20px;
      position: absolute;
      right: 55px;
      width: 100%;
    }
  }

  @include media-breakpoint-only(md) {
    &:not(.itemCardExpanded) {
      bottom: 20px;
      position: absolute;
      right: 55px;
      width: 100%;
    }
  }
}

.actionButton {
  align-self: center;
  display: flex;
}

.copyButton {
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, right 200ms ease;

  &.itemCardExpanded {
    margin-left: 0;
    transition: right 200ms ease;
  }

  .trustDocumentArchiveItem:hover &,
  &.itemCardExpanded {
    opacity: 1;
    visibility: visible;
    transition: opacity 200ms ease, right 200ms ease;
  }

  @include media-breakpoint-only(xs) {
    position: initial;
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-only(sm) {
    position: initial;
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-only(md) {
    position: initial;
    opacity: 1;
    visibility: visible;
  }
}

.signedDocument {
  margin-top: 20px;
}

.errors {
  align-items: center;
  background: var(--navbar-error);
  border-radius: 50%;
  color: var(--main-text-color);
  display: flex;
  font-size: 11px;
  height: 20px;
  justify-content: center;
  left: -6px;
  padding: 3px;
  position: absolute;
  top: -10px;
  width: 20px;
  z-index: 1;
}
