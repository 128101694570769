@import 'styles/mixings';

.governingLaw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.role {
  background: white;
  color: black;
  border: solid 1px var(--main-text-color-alt);
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  transition: background-color 200ms ease, color 200ms ease;

  & .checkbox {
    align-items: center;
    border-right: solid 1px var(--main-text-color-alt);
    color: black;
    display: none;
    font-size: 10px;
    height: 100%;
    min-width: 20px;
    justify-content: center;
    width: 20px;

    & svg {
      width: 15px;
      height: 15px;
    }
  }

  @include media-breakpoint-only(xs) {
    padding: 6px !important;

    .checkbox {
      padding-right: 5px;
    }
  }
}

.selectedUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 20px 50px;

  @include media-breakpoint-only(sm) {
    padding: 20px;
  }
  @include media-breakpoint-only(xs) {
    padding: 20px;
  }

  &:hover {
    background: transparent;
  }
}

.selectedList {
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 5px;

  & > div {
    width: 100%;
  }

  & div {
    cursor: default;
  }

  & .role {
    background: transparent;
    cursor: pointer;
    color: var(--main-text-color-alt);
    padding: 0;
    padding-right: 8px;

    & .checkbox {
      display: flex;
      color: transparent;
    }

    &.governingLaw {
      background: white;
      color: black;

      & .checkbox {
        color: black;
      }
    }

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
  }
}

.roleName {
  padding-left: 10px;
  cursor: pointer !important;
}
