@import '../../styles/mixings.scss';

.fileContainer {
  background: var(--element-background);
  border-radius: 5px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 10px;
  margin-bottom: 10px;
  padding: 15px 30px;
  padding-bottom: 0;

  &.large {
    padding: 20px 50px;
  }

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  @include media-breakpoint-only(xs) {
    margin-top: 15px;

    &.large {
      padding: 20px 20px;
    }
  }
}

.textareaContainer {
  grid-column: auto / span 2;
  margin-bottom: 10px;
}

.fileContainer-enter {
  opacity: 0;
}

.fileContainer-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fileContainer-exit {
  opacity: 1;
}

.fileContainer-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.fileDataContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-gap: 20px;

    input {
      border-radius: 100px;
      border-left: 1px solid var(--inputs-border-color);
    }
  }
}

.deleteFileIcon {
  align-self: end;
  cursor: pointer;
  fill: var(--main-text-color);
  margin-bottom: 12px;
  transition: fill 200ms ease;

  &:hover {
    fill: var(--main-color);
    transition: fill 200ms ease;
  }
}

.multipleDocumentsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.noDocumentsAttached {
  align-items: center;
  color: var(--main-text-color-alt);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  text-align: center;
}

.addDocumentButton {
  margin-bottom: 20px;
  margin-top: 20px;
}

.addDocumentButtonTop {
  order: -1;
}

.literalCategory {
  color: var(--inputs-border-color);
  display: flex;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 10px;
  white-space: pre-wrap;

  svg {
    margin-right: 10px;
  }
}

.literalMultipleDocument {
  padding-top: 10px;
}
