@import '../../styles/mixings.scss';

.container {
  align-items: center;
  display: flex;
  margin-bottom: 60px;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
}

.partAsset {
  flex: 1;
  position: relative;

  &.partAssetNoBenefs {
    &:after {
      background: #ffffff;
      box-shadow: 0 0 6px 0 rgb(255, 255, 255);
      content: ' ';
      display: block;
      height: 2px;
      line-height: 2px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 50%;
      z-index: -1;
    }
  }

  @include media-breakpoint-only(xs) {
    width: 100%;
    &.partAssetNoBenefs {
      &:after {
        height: calc(50% + 40px);
        width: 2px;
        left: 40px;
        top: 100%;
      }
    }
  }
}

.partBenefs {
  display: grid;
  flex: 1;
  grid-row-gap: 30px;

  @include media-breakpoint-only(xs) {
    width: 100%;
    padding-left: 64px;
    display: block;
  }
}

.noBenefs {
  color: var(--main-text-color-alt);
}

.benefBubbleContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  &:after {
    background: #ffffff;
    box-shadow: 0 0 6px 0 rgb(255, 255, 255);
    content: ' ';
    display: block;
    height: 2px;
    line-height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    z-index: -1;
  }

  &:not(.lonely):before {
    background: #ffffff;
    box-shadow: 0 0 6px 0 rgb(255, 255, 255);
    content: ' ';
    display: block;
    height: 145%;
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    z-index: -1;
  }

  &:first-child:before {
    bottom: auto;
    height: 91%;
    top: 50%;

    @include media-breakpoint-only(xs) {
      left: -24px;
    }
  }

  &:last-child:before {
    height: 50%;
    top: 0;

    @include media-breakpoint-only(xs) {
      left: -24px;
    }
  }

  @include media-breakpoint-only(xs) {
    margin-top: 30px;

    &.lonely {
      margin-top: 40px;
    }

    &:not(.lonely):before {
      left: -24px;
    }

    &:after {
      display: block;
      height: 2px;
      line-height: 2px;
      left: -24px;
      top: 50%;
      width: 50%;
    }
  }
}

.bubble {
  background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(141, 141, 141));
  border: solid 2px rgb(255, 255, 255);
  border-radius: 36px;
  box-shadow: 0 0 6px 0 rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  width: 70%;

  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.bubbleTitle {
  color: var(--button-default-color);
  font-size: 18px;
  display: flex;
  align-items: center;

  .text {
    max-width: 400px;

    @include cut-text-with-ellipsis();

    @include media-breakpoint-only(xs) {
      max-width: 190px;
    }
  }
}

.bubbleIcon {
  margin-right: 5px;
}

.bubbleContent {
  color: var(--main-text-color-alt);
  font-size: 14px;
}

.bubbleGovernorContainer {
  color: var(--main-text-color);
  font-size: 14px;
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
}

.bubbleGovernor {
  background: var(--secondary-color);
  padding: 2px 7px;
  border-radius: 10px;
  margin: 5px 5px 0 0;
}
