@import '../../styles/mixings.scss';

.titlePermissions {
  color: var(--main-text-color-alt);
  font-size: 14px;
}

.selectedPermissions {
  font-size: 15px;
  margin-top: 5px;
  background: var(--element-background);
  color: var(--main-text-color);
  border-top: 7px solid;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  padding-left: 40px;
  transition: border-color 200ms ease;
}

.permissions {
  margin-top: 5px;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 8fr 1fr 8fr;
  margin-bottom: 30px;

  @media (max-width: $sm) {
    grid-gap: 0;
    grid-template-columns: none;
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 80px;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-only(xs) {
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.btnAddArrow {
  margin-bottom: 10px;

  @include media-breakpoint-only(xs) {
    margin-bottom: 0;
    margin-right: 10px;
    transform: rotate(90deg);
  }
}

.btnRemoveArrow {
  @include media-breakpoint-only(xs) {
    transform: rotate(90deg);
  }
}

.btnPermissions:focus {
  outline: none;
}

.btnContainerRight {
  order: 1;

  @media (max-width: $sm) {
    order: 2;
  }
}

.noPermissionsSelected {
  text-align: center;
}
