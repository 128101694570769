.container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.card {
  align-items: center;
  background: var(--history-card-active);
  border: 1px solid var(--history-card-border);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px 30px;
}

.buttonsContainer {
  align-self: flex-end;
  justify-self: flex-end;
  position: fixed;
  display: flex;
  top: 92vh;
}

.saveButton {
  margin-right: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 10px;
}

.title {
  
}
