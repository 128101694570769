.expandableButton {
  align-items: center;
  border: 1px solid var(--button-default-border);
  border-radius: var(--button-border-radius);
  color: var(--main-text-color);
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  transition: all 150ms ease;

  &.big {
    justify-content: center;
    min-height: 40px;
    min-width: 40px;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    padding-right: 0;
    transition: max-width 150ms ease, padding-right 150ms ease;
    max-width: 0;
  }

  svg {
    margin-right: 0 !important;
    transition: margin 150ms ease;
  }

  &:hover {
    background: var(--button-default-background);
    color: var(--button-default-color);
    transition: all 150ms ease;

    .text {
      max-width: 200px;
      padding-right: 15px;
      transition: max-width 150ms ease, padding-right 150ms ease;
    }

    svg {
      margin-left: 10px;
      margin-right: 5px !important;
      transition: margin 150ms ease;
    }
  }
}
