@import '../../styles/mixings.scss';

.cardContainer {
  align-items: center;
  background: var(--history-card-inactive);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 30px 50px;
  transition: background 200ms ease;

  &:hover {
    background: var(--history-card-active);
    transition: background 200ms ease;
  }

  @include media-breakpoint-only(sm) {
    padding: 20px;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px;
  }
}

.cardLeft {
  flex: 1;
}

.title {
  font-family: 'Merriweather', serif;
  font-size: 20px;
  margin-top: 7px;
}
