.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logoContainer {
  height: 100%;
  display: block;
}

.logo {
  height: 45px;
  align-content: center;
  color: var(--signin-logo);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
  justify-content: center;
  margin: 50px 0;
  text-transform: uppercase;
}

.logoBrand {
  text-align: center;
  margin-top: 13px;
}

.text {
  color: var(--main-text-color);
  width: 90%;
}
