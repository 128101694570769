@import '../../styles/mixings.scss';

.navBarContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowLeftIcon {
  cursor: pointer;
  display: none;
  left: -100vw;
  margin-bottom: 15px;

  @include media-breakpoint-only(xs) {
    left: 0;
    display: block;
  }
}

.mainNavbarLogo {
  margin-bottom: 35px;
}

.mainNavbarIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 34px;
  color: var(--main-text-color);
  justify-content: center;
  margin-bottom: 10px;
  transition: all 200ms ease;
  width: 34px;

  &:hover {
    background: var(--navbar-background-active);
    border-radius: 100px;
    color: var(--secondary-color);
    transition: all 200ms ease;
  }
}

.mainNavbarCreateTrust {
  align-items: center;
  background: var(--navbar-selected-bg);
  border-radius: 100px;
  color: var(--main-text-color);
  display: flex;
  justify-content: center;
  height: 34px;
  transition: all 200ms ease;
  width: 34px;

  &:hover {
    background: var(--navbar-background-active);
    color: #000000;
  }
}

.avatar {
  border: 1px solid #eaeaea;
  border-radius: 100px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  height: 35px;
  transition: transform 200ms ease-out;
  width: 35px;

  &:hover {
    animation: bounce 400ms;
    transition: transform 200ms ease-out;
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.buttonsMenu {
  padding: 0 !important;
}

.buttonsMenuContainer {
  display: flex;
  flex-direction: column;
}

.menuOption {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: initial;
  transition: color 300ms ease;

  &:hover {
    color: var(--main-color);
    transition: color 300ms ease;
  }
}

.menuIcon {
  margin-top: 5px;
  margin-right: 5px;
}
