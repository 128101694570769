.modalContainer {
  max-width: 600px;
  text-align: left;
}

.message {
  color: var(--sub-menu-color);
  margin-bottom: 20px;
  text-align: center;
}

.errorItem {
  padding-bottom: 10px;
}
