@import 'styles/mixings.scss';

.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImage {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  max-height: 100px;
  background-size: contain;
  border-radius: 50%;
  border: 2px solid var(--main-text-color-alt);
}

.dataContainer {
  margin-top: 20px;
  display: grid;
  grid-template: auto / 1fr 1fr;
  grid-gap: 15px;
  width: 100%;
  height: 125px;

  @include media-breakpoint-only(xs) {
    grid-template: auto / 1fr;
  }
}

.errors {
  align-self: flex-start;
  margin-top: 20px;
}

.imageContainer {
  position: relative;
}

.actionsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    position: fixed;
    align-items: cender;
    width: 100%;
    bottom: 30px;
    height: 50px;
    justify-content: center;
    right: 0;
  }
}

.unsetChanges {
  visibility: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--secondary-color);
  border-radius: 50px;
  padding: 0 10px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  transition: visibility 0.3s linear, transform 0.3s ease-in-out, width 0.3s linear;

  & .unsaved {
    font-size: 12px;
    color: var(--sub-menu-color);
  }

  & .cloudIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &.show {
    width: 170px;
    visibility: visible;
    transform: translate(-36px, 0px);
  }
}

.saveButtonContainer {
  position: relative;

  &:hover {
    & .unsetChanges.show {
      width: 200px;
      transform: translate(-155px, 0px);

      @include media-breakpoint-only(xs) {
        width: auto;
        transform: translate(-36px, 0px);
      }
    }
  }
}
