/* sizes */
$lg: 1240px;
$md: 992px;
$sm: 768px;
/* sizes */

@mixin media-breakpoint-only($viewport) {
  @if $viewport == xs {
    @media (max-width: $sm - 1px) {
      @content;
    }
  }

  @if $viewport == sm {
    @media (min-width: $sm) and (max-width: $md - 1px) {
      @content;
    }
  }

  @if $viewport == md {
    @media (min-width: $md) and (max-width: $lg - 1px) {
      @content;
    }
  }

  @if $viewport == lg {
    @media (min-width: $lg) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($viewport) {
  @if $viewport == sm {
    @media (max-width: $sm) {
      @content;
    }
  }
  @if $viewport == md {
    @media (max-width: $md) {
      @content;
    }
  }
  @if $viewport == lg {
    @media (max-width: $lg) {
      @content;
    }
  }
}

@mixin cut-text-with-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
