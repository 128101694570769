@import '../../styles/mixings.scss';

.row {
  display: grid;
  position: relative;
  padding: 20px 50px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
  grid-template-areas:
    'name extraData'
    'type extraDataSecondary';
  width: 100%;

  &.noName {
    grid-template-areas:
      'type extraData'
      'type extraDataSecondary';
  }

  @include media-breakpoint-only(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'name'
      'type'
      'extraData'
      'extraDataSecondary';
    grid-row-gap: 5px;
    padding: 20px;
  }

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'name'
      'type'
      'extraData'
      'extraDataSecondary';
    grid-row-gap: 5px;
    padding: 20px;
  }
}

.name {
  font-family: 'Merriweather', serif;
  font-size: 19px;
  grid-area: name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  align-items: flex-start;
  color: var(--tables-title-color);
  display: flex;
  font-size: 14px;
  grid-area: type;
  line-height: 14px;

  .typeIcon {
    margin-right: 5px;
  }
}

.extraData {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--main-text-color);
  font-size: 14px;
  grid-area: extraData;
  text-align: right;

  @include media-breakpoint-only(xs) {
    text-align: left;
  }
}

.extraDataSecondary {
  grid-area: extraDataSecondary;
}

.error {
  background: var(--navbar-error);
  color: var(--main-text-color);
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}
