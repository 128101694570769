.yesNoContainer {
  margin-top: 15px;
}

.okButton {
  margin-right: 5px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  font-family: var(--mainFont);
  margin-bottom: 15px;
}
