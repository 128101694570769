@import '../../styles/mixings.scss';

.itemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activityTableItemCPartiesName {
  align-items: flex-start;
  color: var(--element-main-color);
  display: flex;
  font-size: 14px;
  grid-area: cpartiesName;
  text-transform: capitalize;
}

.activityTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
}

.activityTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
}

.activityTableItemBadge {
  grid-area: itemBadge;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--element-main-color);
}

.activityTableItemStatus {
  grid-area: itemStatus;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 5px;
  color: var(--element-main-color);
}

.activityTableItemEntity {
  grid-area: itemEntity;
  font-size: 14px;
  text-transform: capitalize;
}

.activityTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  grid-template: repeat(2, 1fr) / 1fr;
  grid-template-areas:
    'itemName itemStatus'
    'itemBadge itemStatus'
    'itemEntity itemStatus'
    'updatedAt itemStatus'
    'createdAt itemStatus';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
  }
}

.activityTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr;
  grid-template-areas:
    'itemName itemName'
    'itemBadge itemBadge'
    'itemEntity itemEntity'
    'updatedAt updatedAt'
    'createdAt createdAt'
    'itemStatus itemStatus';
  padding: 30px 30px 30px 32px;

  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  .activityTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }
}

.activityTableItem--list {
  .activityTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.activityTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'itemName itemEntity updatedAt createdAt itemStatus'
    'itemBadge itemEntity updatedAt createdAt itemStatus';
  padding: 20px 10px;

  & .itemName,
  & .activityTableItemCreatedAt,
  & .activityTableItemUpdatedAt,
  & .activityTableItemSettlorName,
  & .activityTableItemBadge {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  .activityTableItemCreatedAt {
    display: flex;
  }

  .typeIcon {
    position: relative;
    top: 3px;
  }
}

.badgeIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
