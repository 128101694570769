.subMenuButton {
  align-items: center;
  border-radius: 100px;
  color: var(--sub-menu-color);
  display: flex;
  font-size: 14px;
  height: 40px;
  margin-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  position: relative;
  transition: all 200ms ease;
  width: 100%;
  min-width: 41px;

  svg {
    color: var(--sub-menu-color);
    margin-right: 15px;
  }

  &:hover,
  &.subMenuButtonActive {
    background: var(--navbar-selected-bg);
    transition: all 200ms ease;

    svg {
      color: var(--selection-color);
    }
  }

  &.subMenuButtonActive {
    color: var(--main-color);
    transition: color 200ms ease;
  }
}

.error {
  background: var(--navbar-error);
  color: var(--main-text-color);
  position: absolute;
  top: -6px;
  left: 0;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}

.collapsed {
  padding-left: 13px;
  width: auto;
  display: inline-flex;
  justify-content: center;

  &:hover {
    background: var(--secondary-color);
  }

  &:not(:hover) {
    padding-right: 0;
    padding-left: 0;

    svg {
      margin-right: 0;
    }
  }

  &:hover:not(.isMobile) {
    min-width: 100%;
    width: fit-content;
  }
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .collapsed & {
    display: none;
  }

  .collapsed:hover:not(.isMobile) & {
    display: inline-block;
  }
}
