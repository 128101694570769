@import '../../../styles/mixings.scss';

.inputsContainer {
  margin-bottom: 30px;
  grid-gap: 20px;

  &:not(.twoColumns) {
    display: grid;
  }

  &.twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr !important;
    margin-bottom: 0;
  }

  @include media-breakpoint-only(sm) {
    grid-template-columns: 1fr !important;
    padding-bottom: 25px;
  }

  @include media-breakpoint-only(xs) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
    padding-bottom: 25px;
  }

  &.insideModal {
    grid-gap: 25px;
  }
}
