.goBackButton {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.subtitleContainer {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.title {
  align-items: center;
  display: flex;
  flex: 1;

  svg {
    margin-right: 10px;
  }
}
