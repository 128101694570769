.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.firstValuation {
  font-size: 14px;
}

.tooltipLink {
  color: var(--main-text-color-alt);
  text-decoration: underline;
  font-size: 14px;

  &:hover {
    color: var(--secondary-color-hover);
  }
}
