@import '../../styles/mixings.scss';

.contactInformationTabValue,
.contactInformationTab {
  & > div {
    margin-bottom: 20px;
  }
}

.contactInformationTabValue {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
