.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertIcon {
  color: var(--signin-error-color);
  margin-right: 10px;
  margin-top: 3px;
}

.checkIcon {
  color: var(--status-resolved);
  margin-right: 10px;
  margin-top: 3px;
}

.downloadModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalButton {
  margin-top: 15px;
}

.modalSubtitle {
  font-family: var(--subtitleFont);
  font-size: 18px;
  margin-bottom: 15px;
}

.modalSubtitleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: center;
}
