.destinationAssetsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.primaryButton {
  margin-right: 5px;
}

.destinationForm {
  margin-bottom: 15px;
}

.genericInput {
  margin-bottom: 15px;
}

.noVotingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: var(--main-text-color-alt);
}

.noVotingLegend {
  margin-bottom: 15px;
}
