.highlightContainer {
  flex: 1;
  position: relative;

  &:focus {
    outline: none;
  }
}

.highlighter {
  line-height: 19px;
  padding: 40px 30px 30px 30px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  text-align: justify;
  position: relative;
}

.selectionText {
  position: relative;
  z-index: 10;

  &:not(.selectionMode) {
    visibility: hidden;
    user-select: none;
  }
}

.rangeSelectionHighlight {
  color: var(--button-default-color);
  left: 0;
  position: absolute;
  top: 0;
  user-select: none;
  z-index: 5;

  &.rangerSelectionSelectionMode {
    color: transparent;
  }
}

.highlightRule {
  cursor: pointer;
  transition: background 200ms ease;
}

.floatingButton {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  z-index: 11;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: visibility 0s 200ms, opacity 200ms linear;

  & .floatingButtonBackground {
    position: absolute;
    width: 51px;
    height: 41px;
    z-index: -1;
  }

  & .floatingButtonIcon {
    color: white;
    font-weight: bold;
    margin-left: 8px;
  }
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms linear;
}

.floatingText {
  width: calc(100% - 6px);
  height: 35px;
  position: absolute;
  top: 0;
  border-radius: 20px;
  background-color: var(--button-default-color);
  color: white;
  left: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  opacity: 0;
  transition: all 200ms ease-out;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 34px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid var(--button-default-color);
  }
}

.floatingTextShow {
  top: -7px;
  opacity: 1;
}
