@import 'styles/mixings';

.subtitle {
  color: var(--sub-menu-color);
  font-size: 16px;
  margin-bottom: 18px;
  margin-top: 30px;
}

.containerGoverningLaw {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  width: 100%;
}

.buttonAdd {
  margin-top: 22px;
  justify-content: center;
  margin-left: 20px;
  text-align: center;
}

.error {
  margin-top: 0 !important;
}

.governingLawList {
  margin-top: 30px;
}

.errorMessageModal {
  color: var(--signin-error-color);
}
