.subMenuButton {
  align-items: center;
  border-radius: 100px;
  color: var(--sub-menu-color);
  display: flex;
  font-size: 14px;
  height: 40px;
  margin-bottom: 3px;
  padding-left: 13px;
  padding-right: 13px;
  text-decoration: none;
  transition: background 200ms ease, width 200ms ease;
  white-space: nowrap;
  min-width: 100%;

  svg {
    color: var(--sub-menu-color);
    margin-right: 15px;
    transition: color 200ms ease;
  }

  &:hover,
  &.subMenuButtonActive {
    background: var(--navbar-selected-bg);
    transition: background 200ms ease, width 200ms ease;

    &.primary {
      color: var(--secondary-color);
      background: var(--navbar-background-active);
    }

    svg {
      color: var(--selection-color);
      transition: color 200ms ease;
    }
  }

  &.collapsed:not(:hover) {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    transition: background 200ms ease, width 200ms ease;

    svg {
      margin-right: 0;
      transition: color 200ms ease;
    }
  }

  &.collapsed:hover {
    background: var(--secondary-color);
    width: fit-content;
    transition: background 300ms ease, width 300ms ease;
    will-change: transition;

    .subMenuButtonText {
      animation: animationText 1s ease;
    }

    &.primary {
      color: var(--secondary-color);
      background: var(--navbar-background-active);
    }
  }

  &.subMenuButtonActive {
    color: var(--main-color);
  }
}

.subMenuButtonText {
  .collapsed:not(:hover) & {
    animation: as 1s ease;
    display: none;
  }
}

.subMenuButtonSeparator {
  margin-top: 15px;
}

@keyframes animationText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
