@import 'styles/mixings.scss';

.containerFilterStatus {
  position: relative;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  position: relative;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.containerFilters {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.filter {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & > div {
    display: flex;
  }

  label {
    color: var(--main-text-color-alt);
    margin-right: 10px;
  }

  .filterBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--element-background-odd);
    border-radius: 50px;
    padding: 3px 5px 3px 15px;
    min-width: 105px;
  }

  svg {
    margin-left: 5px;
    width: 20px;
  }
}

.dropdownFilter {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 0;
  flex-direction: column;
  background: white;
  color: black;
  border-radius: 10px;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease-in-out;
  min-width: 105px;
  z-index: 100;

  &.showDropdown {
    visibility: visible;
    opacity: 1;
  }

  & .FilterArrow {
    display: none;
  }

  & > div {
    padding: 5px 20px;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 35px;

    &:hover {
      background: #ededed;
    }

    &.selectedFilter {
      background: var(--main-color);
      color: white;

      &:hover {
        background: var(--main-color);
      }
    }
  }
}
