@import 'styles/mixings';

.card {
  margin-top: 10px;
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  width: 100%;
  height: 97px;
  color: var(--main-text-color);
  padding: 18px 20px 18px 30px;
  text-decoration: none;
  transition: all 500ms ease;
  grid-column: 2;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(lg) {
    display: flex;
    justify-content: space-between;
  }

  @include media-breakpoint-only(sm) {
    height: 105px;
  }

  @include media-breakpoint-only(xs) {
    height: 105px;
  }
}

.containerInformation {
  display: flex;
  flex-direction: column;
  flex: 4;
  max-width: 300px;

  @include media-breakpoint-only(xs) {
    max-width: 215px;
  }
}

.name {
  font-family: var(--mainFont);
  font-size: 16px;
  color: var(--status-execution);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.containerUpdated {
  margin-top: 8px;
  display: flex;
  color: var(--tables-title-color);
  max-width: 300px;

  @include media-breakpoint-only(xs) {
    max-width: 215px;
  }
}

.icon {
  color: var(--tables-title-color);
}

.updated {
  margin-left: 5px;
  font-family: var(--mainFont);
  color: var(--tables-title-color);
  font-family: Verdana;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusContainer {
  min-width: 90px;
  margin-top: 5px;
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  flex: 1;

  @include media-breakpoint-only(xs) {
    margin-top: 10px;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
    color: var(--main-text-color-alt);
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
}

.status {
  flex: 1;
}

.statusExecution {
  background: var(--status-voting);
}

.statusDraft {
  background: var(--status-draft);
}

.statusExecuted {
  background: var(--status-finish);
}

.statusPending {
  background: var(--status-pending);
}

.statusStored {
  background: var(--status-approved);
}
