@import 'styles/mixings';

.gridTable {
  position: relative;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr repeat(3, 60px);
}

.valuesTable {
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
}

.transaction {
  text-align: left !important;
}

.literalLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  cursor: initial;
  min-height: 46px;

  @include media-breakpoint-only(xs) {
    height: auto;
    min-height: 60px;
  }
}

.containerLabel {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 12px;
  margin-bottom: 10px;
  transition: opacity 200ms ease;
}

.labelStatus {
  font-size: 12px;
  padding-left: 11px;
  padding-right: 4px;
  margin-bottom: 10px;
  transition: opacity 200ms ease;
}

.labelsContentResolution {
  display: flex;
  justify-content: space-between;
}

.containerTransactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.literalValue {
  align-items: center;
  border: none;
  color: var(--signin-input-inactive-color);
  display: flex;
  font-family: Verdana, sans-serif;
  cursor: text;
  font-size: 18px;
  flex: 1;
  max-width: 100vw;
  padding: 0;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-right: 25px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.borderBottom {
  display: flex;
  border-bottom: 1px solid var(--settlor-display-border-color);
}

.statusContain {
  justify-content: center;
  display: flex;
  align-items: center;
}

.emptySelection {
  cursor: pointer;
  text-align: center;
  margin-right: 14px;
  margin-left: 14px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  border: 2px solid var(--settlor-display-border-color);
}

.approve {
  cursor: pointer;
  margin-right: 14px;
  margin-left: 14px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: var(--status-stored);
}

.reject {
  cursor: pointer;
  margin-right: 14px;
  margin-left: 14px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: var(--status-terminated);
}

.newVote {
  cursor: pointer;
  margin-right: 14px;
  margin-left: 14px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: var(--main-text-color);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--history-card-border);
}
