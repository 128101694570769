.valuationChangeContainer {
  display: grid;
  grid-template-areas:
    'valuation valuation'
    'corpName corpName'
    'currentValue newValue';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;
}

.field {
  grid-area: valuation;
  text-align: center;
}

.corpName {
  grid-area: corpName;
}

.currentValue {
  grid-area: currentValue;
  text-align: left;
}

.newValue {
  grid-area: newValue;
  text-align: left;
}
