.tree {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.tree__container {
  width: 100%;
  height: 98%;
  border-radius: 5px;
  border: solid 1px rgba(#ffffff, 0.05);
  margin-bottom: 2%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
}

.tree__containerShow {
  opacity: 1;
}

.tree__item {
  width: 270px;
  min-height: 40px;
  background: linear-gradient(to bottom, #ffffff, #8d8d8d);
  border-radius: 25px;
  overflow: hidden;
  font-size: 15px;
  flex-direction: row;
  color: var(--button-default-color);
  stroke-width: 0;
  fill: var(--button-default-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  box-shadow: 0 0 6px 0 #ffffff;
  margin-left: 6px;
  border-bottom: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  border-left: solid 2px #ffffff;
  margin-top: 8px;
  text-overflow: ellipsis;

  & > div {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  & > div:nth-child(1) {
    flex: 1;
  }

  & > div:nth-child(2) {
    width: 20px;
    height: 20px;
  }
}

.tree__itemClick {
  cursor: pointer;
}

.tree__itemName {
  display: -webkit-box;
  max-height: 35px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.tree__itemIcon {
  justify-content: center;
  align-items: center;
  display: flex;

  & svg {
    width: 18px;
    height: 18px;
    color: var(--button-default-color);
  }
}

.zoomControls {
  position: absolute;
  display: flex;
  flex-direction: row;

  right: 20px;
  bottom: 20px;

  & .controlButton {
    margin-left: 10px;
  }
}

.moveControls {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  position: absolute;

  left: 20px;
  bottom: 20px;

  & > button:nth-child(1) {
    grid-column: 2;
    grid-row: 1;
  }

  & > button:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  & > button:nth-child(3) {
    grid-column: 3;
    grid-row: 2;
  }

  & > button:nth-child(4) {
    grid-column: 2;
    grid-row: 3;
  }
}

.controlButton {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: var(--secondary-color);
  border: none;
  color: var(--main-text-color);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--secondary-color-hover);
    color: var(--main-text-color);
  }
}
