@import '../../styles/mixings.scss';

.changePassword {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @include media-breakpoint-only(xs) {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.changePasswordButton {
  font-size: 14px;
}
