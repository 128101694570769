@import '../../styles/mixings.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 60px;

  @include media-breakpoint-only(xs) {
    padding-bottom: 120px;
  }
}

.hidden {
  display: none;
}
