@import '../../styles/mixings.scss';

.andXMoreFixer {
  &:before {
    content: ' ';
  }
}

.andXMore {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color);
  width: fit-content;
  white-space: nowrap;
}

.multipleBeneficiaries {
  max-width: 150px;
  word-break: break-word;

  @include media-breakpoint-only(md) {
    max-width: 100px;
  }

  @include media-breakpoint-only(sm) {
    max-width: 90px;
  }
}

.dottedUnderline {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color);
  width: fit-content;
}
