.card {
  height: 100%;
  background: var(--element-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  text-decoration: none;
  color: var(--main-text-color);
  margin-bottom: 10px;

  &:hover {
    background: var(--element-background-hover);
  }
}

.title {
  font-size: 22px;
  font-family: var(--mainFont);
  margin-bottom: 5px;
}

.authorStamp {
  color: var(--main-text-color-alt);
  margin-bottom: 10px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tag {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--main-text-color);
}
