@import 'styles/mixings.scss';

.tableRowDelete {
  cursor: pointer !important;
  align-items: center;
  background: var(--bg-delete-button);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--color-delete-button);
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: width 200ms ease, background 200ms ease, color 200ms ease;
  width: 0;

  &:hover {
    color: var(--main-text-color);
    background: var(--bg-delete-button-bg);
    transition: background 200ms ease, color 200ms ease;
  }

  &.showDelete {
    width: 50px !important;
  }
}

.tableRowContainer {
  cursor: pointer;
  display: flex;
  transition: width 200ms ease, background-color 200ms ease;

  @include media-breakpoint-only(lg) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }

  @include media-breakpoint-only(md) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }
}
