.container {
  border-bottom: 1px solid var(--element-background);
  margin-bottom: 35px;
}

.title {
  color: var(--main-color);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  padding-bottom: 15px;

  &.titleCollapsed {
    padding-bottom: 0;
  }
  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.description {
  align-items: flex-start;
  color: var(--main-text-color-alt);
  display: flex;
  font-size: 13px;
  margin-top: 10px;
  font-style: italic;
}

.pre {
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descIcon {
  align-items: center;
  display: flex;
  margin-right: 5px;
}

.ruleFullText {
  color: var(--main-text-color);
  margin-top: 10px;
  font-size: 14px;
  padding-bottom: 15px;
}

.collapseSection {
  height: auto;
  opacity: 1;
  overflow: hidden;
  transition: opacity 200ms ease-in;
}

.collapsed {
  height: 0;
  opacity: 0;
  transition: opacity 200ms ease-in;
}
