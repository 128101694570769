@import '../../styles/mixings.scss';

.ruleTitle {
  font-family: 'Merriweather', serif;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ruleDescription {
  align-items: flex-start;
  color: var(--main-text-color-alt);
  display: flex;
  font-size: 13px;
  margin-top: 15px;

  svg {
    margin-right: 10px;
  }
}

.descriptionDisplay {
  font-style: italic;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionsContainer {
  display: flex;
  margin-top: 15px;
}

.actionIcon {
  align-items: center;
  background: var(--main-text-color);
  border-radius: 100px;
  color: var(--button-default-color);
  display: flex;
  justify-content: center;
  height: 27px;
  margin-right: 15px;
  transition: opacity 200ms ease;
  width: 27px;

  &:hover {
    opacity: 0.7;
    transition: opacity 200ms ease;
  }

  &.showTextIcon {
    display: none;

    @include media-breakpoint-only(xs) {
      display: flex;
    }
  }
}
