.valuablesTitle {
  color: var(--main-text-color-alt);
}

.assetsContainer {
  margin-top: 20px;
}

.valuationAssets {
  overflow: hidden;
}
