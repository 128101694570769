@import '../../styles/mixings.scss';

.buttonsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-end;
    bottom: 0;
    height: 50px;
    justify-content: center;
    position: relative;
    right: 0;
    top: 30px;
  }
}

.showSaveToContinue {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  background: var(--secondary-color);
  border-radius: 10px;
  font-size: 13px;
  left: 12px;
  padding: 7px 10px;
  position: absolute;
  text-align: center;
  top: -60px;
  width: 170px;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: var(--secondary-color);
    border-width: 10px;
    margin-left: -10px;
  }
}

@keyframes floating {
  0% {
    transform: translate(0, -5px);
  }
  50% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, -5px);
  }
}

.unsetChanges {
  visibility: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--secondary-color);
  border-radius: 50px;
  padding: 0 10px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  transition: visibility 0.3s linear, transform 0.3s ease-in-out, width 0.3s linear;

  & span {
    font-size: 12px;
    color: var(--sub-menu-color);
  }

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &.show {
    width: 170px;
    visibility: visible;
    transform: translate(-36px, 0px);
  }
}

.saveButtonContainer {
  position: relative;

  &:hover {
    & .unsetChanges.show {
      width: 200px;
      transform: translate(-155px, 0px);

      @include media-breakpoint-only(xs) {
        width: auto;
        transform: translate(-36px, 0px);
      }
    }
  }
}

.editTrustButtonContainer {
  position: relative;
  display: flex;
}
