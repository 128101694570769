.containerPdf {
  padding: 100px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.containerPdf * {
  color: black !important;
}
