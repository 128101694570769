@import '../../styles/mixings.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
}

.imageContainer {
  position: relative;
}

.tenantUserIcon {
  color: var(--main-text-color);
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--main-color);
  position: absolute;
  top: 54px;
  z-index: 1;
  right: calc(50% + -46px);
}

.tenantUserContainer {
  display: flex;
  align-items: center;
  color: var(--main-text-color) !important;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 15px;
}

.largeInput {
  grid-area: auto / span 2;

  @include media-breakpoint-only(xs) {
    grid-area: auto / auto;
  }
}

.formContainer {
  display: grid;
  width: 100%;
  grid-gap: 20px;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
  flex: 1;
  height: fit-content;

  @include media-breakpoint-only(xs) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    grid-template-columns: 1fr;
  }
}

.error {
  color: var(--signin-error-color);
  font-size: 14px;
  margin-left: 15px;
  margin-top: 5px;
}

.activeContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--settlor-display-border-color);
}

.toggleActive {
  font-size: 14px;
  color: var(--element-main-color);
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
}

.resendContainer {
  display: flex;
  align-items: center;
}

.resendErrorIcon {
  color: var(--signin-error-color);
  margin-left: 10px;
}

.statusRightSection {
  display: flex;
  align-items: center;
  color: var(--main-text-color);
  text-decoration: underline;
  transition: color 300ms ease;

  &:hover {
    color: var(--main-text-color-alt);
    transition: color 300ms ease;
  }

  svg {
    top: 2px;
    position: relative;
    margin-right: 5px;
    cursor: initial;
  }
}
