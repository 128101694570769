.input {
  align-items: center;
  background: transparent;
  border: 1px solid var(--inputs-border-color);
  box-shadow: none;
  border-radius: 100px;
  color: var(--main-text-color);
  cursor: pointer;
  display: flex;
  font-family: verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  justify-content: space-between;
  height: 40px;
  line-height: 16px;
  outline: none;
  overflow: hidden;
  padding: 0;
  padding-left: 25px;
  padding-right: 80px;
  transition: all 200ms ease;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  &::placeholder {
    color: var(--inputs-placeholder-color);
    transition: all 200ms ease;
  }

  &.error {
    border-color: var(--signin-error-color);
    color: var(--signin-error-color);
  }
}

.inputContainer {
  position: relative;

  .fileIcon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      color: var(--main-color);
      transition: color 200ms ease;
    }
  }
}

.inputFileHidden {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fileIcons {
  display: flex;
  position: absolute;
  align-items: center;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);

  svg {
    color: var(--main-text-color);
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      color: var(--main-color);
      transition: color 200ms ease;
    }
  }
}

.downloadFileBtn {
  svg {
    color: var(--main-text-color);
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      color: var(--main-color);
      transition: color 200ms ease;
    }
  }

  &.circle {
    background: #fff;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:hover {
      svg {
        color: var(--main-color);
        transition: color 200ms ease;
      }
    }

    svg {
      transition: color 200ms ease;
      color: var(--button-default-color);
      margin-left: 0;
    }
  }

  &.noUploadFile {
    cursor: initial;
    background: var(--main-text-color-alt);

    svg {
      color: var(--secondary-color);
      cursor: initial;

      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}

.loadingRing {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 2px solid var(--button-loading-ring);
  border-color: var(--button-loading-ring) transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 18px;
  margin-left: 10px;
  margin-top: 3px;
  width: 18px;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.errorInput {
  color: var(--signin-error-color);
  font-size: 12px;
  padding-left: 20px;
  padding-top: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.errorLiteralInput {
  padding-left: 0;
}
