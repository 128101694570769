@import '../../styles/mixings.scss';

.pageTitleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-self: flex-start;
  width: 100%;
  min-height: 50px;

  @include media-breakpoint-only(xs) {
    margin: 0;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.hasRightSection {
  @include media-breakpoint-only(xs) {
    padding-right: 0;
  }
}

.title {
  color: var(--main-text-color);
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  padding: 0;

  @include media-breakpoint-only(xs) {
    font-size: 20px;
    text-align: center;
    flex: 1;
  }
}

.subtitle {
  color: var(--main-text-color);
  font-family: 'Merriweather', serif;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;

  @include media-breakpoint-only(xs) {
    font-size: 15px;
    text-align: center;
    flex: 1;
  }
}

.rightSection {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-only(xs) {
    flex: 0;
    max-width: initial;
  }
}

.floatBar {
  align-items: center;
  background-color: var(--floating-bar-background);
  display: flex;
  height: 60px;
  justify-content: space-between;
  left: 0;
  margin-bottom: 20px;
  margin-left: -80px;
  margin-top: -60px - 25px;
  padding: 5px 80px;
  position: sticky;
  right: 0;
  top: 0;
  transition: transform 200ms linear;
  width: calc(100% + 160px);
  z-index: 20;
  transform: translateY(-100px);

  @include media-breakpoint-only(md) {
    width: calc(100% + 80px);
    padding: 5px 40px;
    margin-left: -40px;
    margin-top: -60px - 20px;
  }

  @include media-breakpoint-only(sm) {
    width: calc(100% + 40px);
    padding: 5px 20px;
    margin-left: -20px;
    margin-top: -60px - 20px;
  }

  @include media-breakpoint-only(xs) {
    width: calc(100% + 30px);
    padding: 5px 15px;
    margin-left: -15px;
    margin-top: -60px - 20px;
  }

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.show {
  transform: translateY(0);
  position: sticky;
}
