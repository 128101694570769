.containerInput {
  position: relative;
}

.input {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--inputs-border-color);
  border-radius: 100px;
  color: var(--main-text-color);
  font-family: verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0;
  padding-left: 25px;
  padding-right: 25px;
  transition: all 200ms ease;
  width: 100%;

  &::placeholder {
    color: var(--inputs-placeholder-color);
    transition: all 200ms ease;
    cursor: not-allowed;
  }

  &:disabled {
    opacity: 0.3;
    transition: all 200ms ease;
  }

  &:focus {
    color: var(--main-color);
    border-color: var(--main-color);
    transition: all 200ms ease;

    &::placeholder {
      color: var(--main-color-alt) !important;
      transition: all 200ms ease;
    }
  }

  &.error {
    border-color: var(--signin-error-color);
    color: var(--signin-error-color);
  }

  &.hasIcon {
    padding-left: 40px;
  }
}

.errorMessage {
  color: var(--signin-error-color);
  font-size: 14px;
  margin-left: 15px;
  margin-top: 5px;
}

.iconInput {
  position: absolute;
  top: 38px;
  left: 14px;
  color: var(--main-text-color);
}
