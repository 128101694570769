.label {
  font-size: 14px;
  margin-bottom: 10px;
  transition: opacity 200ms ease;

  &.disabled {
    opacity: 0.3;
    transition: opacity 200ms ease;
  }
}

.literalLabel {
  color: var(--main-text-color-alt);
  font-size: 14px;
  margin-bottom: 10px;
}
