.subtitle {
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #afafaf;
}

.containerInformation {
  display: grid;
  margin-top: 16px;
  gap: 15px;
  text-align: center;
}

.textInformation {
  text-align: center;
  color: var(--main-text-color);
  font-size: 12px;
}

.contactUs {
  margin-left: 5px;
  text-decoration: none;
  color: var(--status-executed);
  font-size: 13px;
}

.containerImageLock {
  margin-top: 24px;
  text-align: center;
}

.imageLock {
  width: 273.3px;
  height: 230px;
  opacity: 0.5;
}
