@import '../../styles/mixings.scss';

.ArrowNavGrid {
  display: grid;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr !important;
    grid-gap: 20px !important;
  }
}
