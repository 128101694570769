.hotkeys {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.container:focus,
.hotkeys:focus {
  outline: none;
}
