$signInInputHeight: 53px;
$paddingLeft: 30px;

.signInInputContainer {
  position: relative;
  height: $signInInputHeight;
  margin-bottom: 30px;
  width: 100%;
}

.signInInput {
  background: transparent;
  border: 1px solid var(--signin-input-inactive-color);
  border-radius: 100px;
  color: var(--signin-input-inactive-color);
  height: $signInInputHeight;
  margin: 0;
  padding: 0;
  padding-left: $paddingLeft;
  padding-right: 18px;
  padding-top: 15px;
  outline: none;
  transition: background 300ms ease;
  width: 100%;

  &:focus,
  &.signInInputFocused {
    background: var(--signin-input-bg-selected);
    color: var(--signin-input-color);
    transition: background 300ms ease;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill.hasValue,
  &.signInInputFocused:-webkit-autofill,
  &:-webkit-autofill {
    -webkit-text-fill-color: black;
    box-shadow: 0 0 0 1000px var(--signin-input-shadow) inset;
    transition: background-color 5000s step-end 0s, color 5000s step-end 0s;
    -webkit-appearance: none;
  }

  &.error {
    border: 1px solid var(--signin-error-color);
  }
}

.floatingLabel {
  color: var(--signin-input-inactive-color);
  font-size: 13px;
  height: $signInInputHeight;
  left: $paddingLeft;
  line-height: $signInInputHeight;
  position: absolute;
  pointer-events: none;
  top: 0;
  transition: 0.2s ease all;

  &.inputFocused {
    color: var(--signin-label-color) !important;
  }

  .signInInput:-webkit-autofill + & {
    height: $signInInputHeight - 19;
    line-height: $signInInputHeight - 19;
    top: -2px;
    font-size: 10px;
    color: var(--signin-label-color) !important;
    animation: autofill 0.7s infinite linear;
    transition: color 5000s step-end 0s;
  }

  &.inputFocused,
  &.hasValue {
    height: $signInInputHeight - 19;
    line-height: $signInInputHeight - 19;
    top: -2px;
    font-size: 10px;
  }
}

@keyframes autofill {
  0% {
    color: var(--signin-label-color);
  }

  100% {
    color: var(--signin-label-color);
  }
}

.passwordIcon {
  position: absolute;
  top: 17px;
  cursor: pointer;
  right: 30px;
  transition: color 300ms ease;
}

.iconNoFocus {
  color: var(--main-text-color-alt);
  transition: color 300ms ease;
}
