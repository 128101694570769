@import '../../styles/mixings.scss';

.searchContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding-bottom: 25px;
  height: initial;
  transition: all 300ms ease;

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.hideSearch {
  display: none;
  transition: all 200ms ease;
}

.searchTitle {
  font-size: 18px;

  svg {
    margin-left: 3px;
    position: relative;
    top: 2px;
  }
}

.inputsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 15px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
  }
}
