@import 'styles/mixings';

.statusContainer {
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  width: 100%;
  min-width: 80px;
  margin-top: 4px;

  @include media-breakpoint-only(xs) {
    margin-top: 5px;
  }
}

.inProcess {
  color: var(--dynamicformsubtitle-color);
  text-decoration-line: underline;
  font-size: 12px;
  min-width: 80px;
}

.bullet {
  border-radius: 100px;
  display: block;
  height: 12px;
  margin-right: 10px;
  width: 12px;
}

.statusAbstain {
  background: var(--status-voting);
}

.statusRejected {
  background: var(--status-cancel);
}

.statusPositive {
  background: var(--status-resolved);
}
