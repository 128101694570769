.entityModalTypeTitle {
  align-items: center;
  color: var(--main-color);
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  svg {
    margin-right: 5px;
  }

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
