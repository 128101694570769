@import '../../styles/mixings.scss';

.wrapper {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  padding: 20px 50px;
  position: relative;

  @include media-breakpoint-only(xs) {
    padding: 20px 25px;
  }
}

.error {
  background: var(--navbar-error);
  color: var(--main-text-color);
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}

.documents {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 10px;
  padding-bottom: 10px;

  @include media-breakpoint-only(sm) {
    grid-template-columns: 100%;
    grid-row-gap: 6px;
  }
  @include media-breakpoint-only(xs) {
    grid-template-columns: 100%;
    grid-row-gap: 6px;
  }
}

.sectionTitle {
  order: 1;
  font-size: 22px;
  font-family: 'Merriweather', serif;

  @include media-breakpoint-only(sm) {
    order: 1;
  }
  @include media-breakpoint-only(xs) {
    order: 1;
  }
}

.documentType {
  margin-top: 6px;
  margin-bottom: 3px;
  text-align: right;
  color: var(--primary-btn-alt-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-only(sm) {
    margin-top: 2px;
    text-align: left;
  }
  @include media-breakpoint-only(xs) {
    margin-top: 2px;
    text-align: left;
  }
}

.unsignedDoc {
  @include media-breakpoint-only(sm) {
    order: 3;
  }
  @include media-breakpoint-only(xs) {
    order: 3;
  }
}

.subtitle {
  color: var(--main-text-color-alt);
  font-size: 14px;

  @include media-breakpoint-only(sm) {
    order: 2;
  }
  @include media-breakpoint-only(xs) {
    order: 2;
  }
}

.downloadLinkContainer {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.downloadBtn {
  margin-right: 15px;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 14px;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.signedDoc {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0;

  @include media-breakpoint-only(sm) {
    order: 4;
    margin-top: 7px;
  }

  @include media-breakpoint-only(xs) {
    order: 4;
    margin-top: 7px;
  }
}
