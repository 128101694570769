@import '../../styles/mixings.scss';

.status {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--main-text-color-alt);
  grid-area: auto / span 2;
  margin-top: 15px;
  border-bottom: 1px solid var(--settlor-display-border-color);
  padding-bottom: 5px;

  @include media-breakpoint-only(xs) {
    grid-area: auto / auto;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 2px;
}

.rightSection {
  display: flex;
}
