$bodyBackground: linear-gradient(to bottom, rgba(11, 55, 77, 0) 0%, #021722 25%),
  linear-gradient(to bottom, #021722, #021722),
  radial-gradient(circle at right, #1b1b1b 2px, transparent 2px),
  radial-gradient(circle at right, #1b1b1b 2px, transparent 2px),
  radial-gradient(circle at left, #1b1b1b 2px, transparent 2px),
  radial-gradient(circle at left, #1b1b1b 2px, transparent 2px),
  repeating-linear-gradient(45deg, #1b1b1b, #1b1b1b 1px, transparent 1px, transparent 14px),
  repeating-linear-gradient(-45deg, #1b1b1b, #1b1b1b 1px, transparent 1px, transparent 14px),
  #111111;
$bodyBackgroundSize: 100vw 100vh, auto auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px, 20px 20px,
  20px 20px;
$bodyBackgroundPosition: 0 0, 0 100vh, 0 0, 10px 10px, 0 0, 30px 10px, 20px 0, 0 0, 0 0;
$bodyBackgroundRepeat: no-repeat, no-repeat, repeat, repeat, repeat, repeat, repeat, repeat;

:root {
  /* main colores */
  --main-color: #bb9314;
  --main-color-alt: #886c0f;
  --secondary-color: #0e2e3f;
  --secondary-color-hover: #154761;
  --bold-color: #a47e31;

  --sub-menu-color: #afafaf;

  --primary-btn-main-color-1: #af8614;
  --primary-btn-main-color-2: #f35f5a;
  --primary-btn-alt-color-1: #f35f5a;
  --primary-btn-alt-color-2: #bb9314;

  --default-text-color: #000000;
  --main-text-color: #ffffff;
  --main-text-color-alt: #6d6d72;

  /* buttons */
  --button-default-color: #031823;
  --button-default-color-hover: var(--secondary-color-hover);
  --button-default-background: #ffffff;
  --button-default-background-hover: #cccccc;
  --button-primary-box-shadow-hover: rgba(187, 147, 20, 0.4);
  --button-default-border: #ffffff;
  --button-loading-ring: #ffffff;
  --button-border-radius: 100px;
  --button-bold-background: linear-gradient(
    to left,
    var(--primary-btn-main-color-2),
    var(--primary-btn-main-color-1)
  );
  --button-bold-outline: var(--bold-color);

  /* yes / no toggle*/
  --yes-no-toggle-bg: rgba(216, 216, 216, 0.23);

  /* tabs */
  --tabs-border-color-inactive: rgba(255, 255, 255, 0.5);
  --tabs-color-active: var(--main-text-color);

  /* side modal */
  --sidemodal-overlay-color: rgba(0, 0, 0, 0.7);
  --sidemodal-body-background: linear-gradient(to bottom, #0b374d, #000000);
  --sidemodal-overlay-animation: rgba(0, 0, 0, 0.9);

  /* toast */
  --sidemodal-toast-background: var(--sidemodal-body-background);

  /* base modals */
  --modal-background: linear-gradient(to bottom, rgb(11, 55, 77), rgb(0, 0, 0));
  --modal-overlay: var(--sidemodal-overlay-color);

  /* datepicker */
  --datepicker-background: #ffffff;

  /* table pager */
  --tablepager-background-active: #ffffff;
  --tablepager-background-inactive: var(--secondary-color);

  /* sign in */
  --signin-input-shadow: #ffffff;
  --signin-input-inactive-color: rgba(255, 255, 255, 0.7);
  --signin-logo: #ffffff;
  --signin-label-color: #000000;
  --signin-input-color: #000000;
  --signin-input-bg-selected: rgba(255, 255, 255, 0.7);
  --signin-box-bg: linear-gradient(to bottom, rgba(10, 49, 68, 1) 0%, rgba(0, 0, 0, 1) 100%);
  --signin-error-color: #ab1818;

  /* navbar */
  --navbar-background: linear-gradient(to bottom, #0b374d, #000000);
  --navbar-background-active: var(--main-text-color);
  --avatar-border: #eaeaea;
  --navbar-selected-bg: rgba(255, 255, 255, 0.05);
  --navbar-submenu-bg: linear-gradient(to bottom, #071f2c, #000000);
  --navbar-collapsed-border: rgba(255, 255, 255, 0.1);
  --navbar-collapsed-border-hover: rgba(255, 255, 255, 0.3);
  --navbar-collapsed-icon: #ffffff;
  --navbar-error: var(--signin-error-color);

  /* settlor display */
  --settlor-display-border-color: rgba(216, 216, 216, 0.23);

  /* inputs */
  --inputs-border-color: #afafaf;
  --inputs-placeholder-color: #8a8a8a;
  --inputs-disabled-bg: #8a8a8a;
  --inputs-disabled-placeholder: #cccccc;
  --inputs-multivalue-bg: #154761;

  /* form select */
  --form-select-option-color: #8a8a8a;
  --form-select-option-focused-color: #000000;

  /* trust subtitle */
  --dynamicformsubtitle-color: #afafaf;

  /* tables */
  --tables-title-color: #afafaf;

  /* trusts list */
  --status-draft: #6d6d72;
  --status-stored: #65a64b;
  --status-pending: #c4c452;
  --status-executed: #bb9314;
  --status-execution: #ffffff;
  --status-terminated: var(--signin-error-color);

  --status-published: var(--status-stored);

  --element-background: rgba(255, 255, 255, 0.05);
  --element-background-selected: rgba(255, 255, 255, 0.15);
  --element-background-odd: rgba(255, 255, 255, 0.1);
  --element-background-hover: rgba(255, 255, 255, 0.25);
  --element-border-color: #35464e;
  --element-main-color: #ababab;

  /* loading */
  --loading-ring-color: #ffffff;

  /* delete sections */
  --bg-delete-button: #ffffff;
  --bg-delete-button-bg: var(--signin-error-color);
  --color-delete-button: var(--secondary-color);

  /* letter of wishes rchive */
  --history-card-inactive: #132d3a;
  --history-card-active: #253f4d;
  --history-card-border: #0f232d;
  --history-connector: #2d4c5c;
  --history-bullet-inner-circle: #d9ebfe;
  --history-bullet-inactive: #132d3a;
  --history-bullet-active: #a39542;
  --history-low-active: #65a64b;
  --history-low-draft: #afafaf;
  --history-low-inactive: #6d6d72;

  /* transactions status */
  --status-approved: #65a64b;
  --status-finish: #bb9314;
  --status-voting: #ffffff;
  --status-rejected: var(--signin-error-color);
  --status-cancel: var(--signin-error-color);
  --status-resolved: #65a64b;

  /* custom toolbar */
  --custom-toolbar-shadow: rgba(255, 255, 255, 0.2);
  --custom-toolbar-background: rgba(255, 255, 255, 0.2);
  --custom-toolbar-background-thumb: rgba(0, 0, 0, 0.5);
  --custom-toolbar-background-inactive: rgba(0, 0, 0, 0.4);

  /* freeze modal */
  --checked-color: var(--history-low-active);

  /* toolTips */
  --primary-tooltip-background: #191919;
  --secondary-tooltip-background: #000000;

  /* floating bar */
  --floating-bar-background: #0a2532;

  // Typography
  --mainFont: Merriweather, serif;
  --subtitleFont: Verdana, sans-serif;

  // Decorations
  --decoration-primary: var(--element-background-hover);
  --decoration-secondary: var(--element-background);
}
