@import 'styles/mixings';

.yesNoToggle {
  align-items: center;
  border-bottom: 1px solid var(--yes-no-toggle-bg);
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  @include media-breakpoint-only(xs) {
    padding-right: 10px;
  }

  &.literalYesNo {
    align-items: flex-start;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
}

.simple {
  align-items: center !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.labelWrapper {
  display: flex;
  align-items: center;
}

.warningIcon {
  margin-left: 15px;
  display: flex;
  align-items: flex-end;
  color: var(--button-bold-outline);
}

.tooltip {
  text-align: center;
}
