.deleteRoles {
  display: grid;
  grid-template: auto / 1fr;
  grid-row-gap: 5px;
}

.name {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--main-color-alt);
  }
}
