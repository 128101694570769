@import '../../styles/mixings.scss';

.container {
  color: var(--main-text-color-alt);
  margin-top: 50px;
  text-align: center;
  @include media-breakpoint-only(xs) {
    margin-top: 0;
  }
}

.text {
  font-size: 16px;
}
