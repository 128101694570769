@import 'styles/mixings.scss';

.literalLabel {
  display: flex;
  flex-direction: column;
  height: fit-content;
  cursor: initial;
  min-height: 46px;

  @include media-breakpoint-only(xs) {
    height: auto;
    min-height: 60px;
  }
}

.literalValue {
  align-items: center;
  border: none;
  color: var(--main-text-color);
  display: flex;
  font-family: Verdana, sans-serif;
  cursor: text;
  font-size: 14px;
  flex: 1;
  max-width: 100vw;
  padding: 0;
  padding-bottom: 7px;
  padding-right: 25px;
  white-space: pre-wrap;
  word-wrap: break-word;

  &.print {
    color: var(--secondary-color-hover);
  }
}

.icon {
  justify-content: space-between;
  padding-right: 10px;
}

.iconClick {
  cursor: pointer;
}

.borderBottom {
  border-bottom: 1px solid var(--settlor-display-border-color);
}
