@import 'styles/mixings';

.container {
  position: relative;
  transition: all 500ms ease;

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  @include media-breakpoint-only(lg) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }
}

.contactTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  grid-template-areas:
    'name name name name'
    'email email email email'
    'updatedAt updatedAt updatedAt updatedAt'
    'contacted contacted contacted contacted';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 30px 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
    row-gap: 4px;
    grid-template-areas:
      'name name name name'
      'email email email email'
      'updatedAt updatedAt updatedAt updatedAt'
      'contacted contacted contacted contacted';
  }
}

.contactTableItem--list {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  
  grid-template-columns: 0.6fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
  
  grid-template-areas:
    'name name name contacted'
    'email email email contacted'
    'updatedAt updatedAt updatedAt contacted';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 60px 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  .contactTableItemContacted {
    justify-self: flex-end;
  }

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
    row-gap: 4px;
    grid-template-areas:
      'name name name name'
      'email email email email'
      'updatedAt updatedAt updatedAt updatedAt'
      'contacted contacted contacted contacted';
  }
}

.contactTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 1fr 1fr 1fr 230px;
  grid-template-areas: 'name email updatedAt contacted';
  padding: 20px 10px;
  & .contactTableItemName,
  & .contactTableItemEmail,
  & .contactTableItemUpdatedAt,
  & .contactTableItemContacted {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    grid-template-columns: 1fr 1fr 1fr 230px;
    grid-template-areas: 'name email updatedAt contacted';
  }
}

.contactTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: repeat(4, 1fr) / 1fr 1fr;
  grid-template-areas:
    'name name name name'
    'email email email email'
    'updatedAt updatedAt updatedAt updatedAt'
    'contacted contacted contacted contacted';
  padding: 30px;
  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  .contactTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
    grid-column-end: 3;
  }
}

.contactTableItemName {
  font-size: 20px;
  font-family: var(--mainFont);
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: name;
}

.typeIcon {
  position: relative;
}

.contactTableItemContacted {
  grid-area: status;
}

.contactTableItemContacted {
  grid-area: contacted;
}

.contactTableItemEmail {
  align-items: flex-start;
  color: var(--element-main-color);
  padding: 0 0 2px 0;
  display: flex;
  font-size: 14px;
  grid-area: email;
}

.contactTableItemUpdatedAt {
  color: var(--main-text-color-alt);
  font-size: 14px;
  grid-area: updatedAt;
  align-items: center;
  font-style: italic;
  grid-column-end: 3;
}

.containerToBeContacted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wishesToBeContacted {
  margin-top: 3px;
  color: var(--main-text-color);
  font-size: 12px;
  text-transform: uppercase;
  grid-area: wishesContacted;
}

.notWishesToBeContacted {
  margin-top: 3px;
  color: var(--main-text-color);
  font-size: 12px;
  text-transform: uppercase;
  grid-area: wishesContacted;
}

.statusToBeContacted {
  border-radius: 100px;
  display: block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  background: var(--status-approved);
}

.statusNotToBeContacted {
  border-radius: 100px;
  display: block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  background: var(--signin-error-color);
}

.tableRowDelete {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background: var(--bg-delete-button);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--color-delete-button);
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: width 200ms ease, background 200ms ease, color 200ms ease;
  align-items: center;
  width: 0;
  height: 100%;

  &:hover {
    color: var(--main-text-color);
    background: var(--bg-delete-button-bg);
    transition: background 200ms ease, color 200ms ease;
  }

  &.showSwipeDelete {
    width: 50px !important;
  }
}
