.assetsInfo {
  margin-top: 25px;
}

.andXMore {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  padding-bottom: 15px;
  margin-top: 10px;
  text-decoration: underline;
  transition: opacity 200ms ease;
  width: fit-content;

  &:hover {
    opacity: 0.7;
    transition: opacity 200ms ease;
  }
}
