.ItemBadge {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.badgeIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.subHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'assetName createdAt'
    'author author'
    'description description';
  grid-gap: 15px;
  align-items: center;
  justify-content: flex-start;
}

.subHeaderWithoutName {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'author createdAt'
    'description description';
  grid-gap: 15px;
  align-items: center;
  justify-content: flex-start;

  & .assetName {
    display: none;
  }
}

.assetName {
  grid-area: assetName;
  text-align: left;
}

.createdAt {
  grid-area: createdAt;
  text-align: left;
}

.author {
  grid-area: author;
  text-align: left;
}

.description {
  grid-area: description;
  text-align: left;
  margin-bottom: 15px;
}
