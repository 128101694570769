@import 'styles/mixings';

.card {
  margin-top: 10px;
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  width: 100%;
  height: 62px;
  color: var(--main-text-color);
  padding: 18px 45px 18px 30px;
  text-decoration: none;
  transition: all 500ms ease;
  grid-column: 1;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(lg) {
    display: flex;
    justify-content: space-between;
  }

  @include media-breakpoint-only(md) {
    height: 84px;
  }

  @include media-breakpoint-only(sm) {
    height: 84px;
  }

  @include media-breakpoint-only(xs) {
    height: 92px;
  }
}

.containerInformation {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.name {
  font-family: Verdana;
  font-size: 11px;
  text-transform: capitalize;
  color: var(--status-finish);
}

.containerUpdated {
  margin-top: 5px;
  font-size: 14px;
  font-family: Verdana;
  display: flex;
  color: var(--tables-title-color);
  max-width: 300px;
}

.icon {
  color: var(--tables-title-color);
}

.updated {
  font-family: var(--mainFont);
  color: var(--tables-title-color);
  font-family: Verdana;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusContainer {
  min-width: 80px;
  margin-top: 5px;
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  flex: 2;

  @include media-breakpoint-only(xs) {
    margin-top: 8px;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
    color: var(--main-text-color-alt);
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
}

.status {
  flex: 1;
}

.statusVoting {
  background: var(--status-voting);
  // background: var(--status-voting);
}

.statusRegected {
  background: var(--status-rejected);
}

.statusApproved {
  background: var(--status-approved);
}

.statusFinished {
  background: var(--status-approved);
}

.statusStored {
  background: var(--status-finish);
}

.statusPending {
  background: var(--status-pending);
}

.complexResolution {
  background: var(--status-rejected);
}
