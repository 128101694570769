@import '../../styles/mixings.scss';

.container {
  align-items: end;
  display: grid;
  grid-template-columns: 2fr 1fr 40px;
  grid-column-gap: 40px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr 1fr 40px;
    grid-row-gap: 15px;

    > :nth-child(1) {
      grid-column: 1 / span 3;
    }

    > :nth-child(2) {
      grid-column: 1 / span 2;
    }
  }
}
