@import '../../styles/mixings.scss';

.wrapper {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding: 25px 50px;
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px 25px;
  }
}

.onlyTitle {
  cursor: initial;
}

.outspreadItem {
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background 200ms ease;
  }
}

.assetTitle {
  font-family: Verdana;
  font-size: 18px;
}

.assetsInfo {
  margin-top: 0px;
  opacity: 0;
  height: 0;
  width: 100%;
  display: grid;
  grid-gap: 5px;
  color: var(--main-text-color-alt);

  @include media-breakpoint-only(xs) {
    grid-gap: 10px;
  }
}

.assetsInfoOutspread {
  margin-top: 10px;
  opacity: 1;
  height: initial;
  transition: all 200ms ease;
}

.title {
  font-family: Verdana;
  font-size: 14px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.item {
  font-family: Verdana;
  font-size: 14px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-only(xs) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3px;
  }
}

.chevronIcon {
  color: var(--main-text-color);
  transition: transform 300ms ease;
}

.outspreadIcon {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.assetIcon {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
