.actionButton {
  margin-left: 15px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.actionButtonRemove {
  &:hover {
    background: var(--signin-error-color);
    border-color: var(--signin-error-color);
    color: var(--main-text-color);
  }
}

.actionButtonPublish {
  &:hover {
    background: var(--status-published);
    border-color: var(--status-published);
    color: var(--main-text-color);
  }
}
