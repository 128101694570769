.termsAndConditions {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  height: 250px;
  padding: 18px;
  overflow-y: scroll;

  p {
    margin-top: 20px;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  h3 {
    text-transform: none;
  }

  /* width */
  &::-webkit-scrollbar {
    color: white;
    cursor: pointer;
    border-radius: 11px;
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 11px;
    background: #888;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 11px;
  }
}

.titleTerms {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--main-text-color);
}

.errorMessage {
  color: var(--signin-error-color);
}
