.errorBox {
  color: var(--signin-error-color);
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 20px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
}

.errorItem {
  font-size: 14px;
  margin-left: 15px;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  grid-gap: 10px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
