@import '../../styles/mixings.scss';

.navBar {
  background-image: var(--navbar-background);
  display: flex;
  height: 100vh;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  opacity: 1;
  transition: opacity 300ms ease;
  z-index: 2;

  @include media-breakpoint-only(xs) {
    left: -100vw;
    display: none;
    position: fixed;
    transition: opacity 300ms ease;
    transform: translate3d(0, 0, 0);
    z-index: 2000;

    .nav-bar-hidden & {
      transition: opacity 300ms ease;
      display: flex;
      left: 0;
    }
  }
}

.mainNavbar {
  align-items: center;
  color: var(--main-text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 36px;
  padding-top: 23px;
  width: 66px;
  z-index: 2;
  background: var(--navbar-background);

  //media queries para Ipad Pro, que excede el tamaño de md
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    padding-bottom: 80px;
  }

  @include media-breakpoint-only(md) {
    padding-bottom: 80px;
  }

  @include media-breakpoint-only(sm) {
    padding-bottom: 80px;
  }

  @include media-breakpoint-only(xs) {
    padding-bottom: 130px;
  }
}

.secondaryNavbar {
  color: var(--main-text-color);
  display: flex;
  overflow: inherit;
  position: relative;
  transition: width 200ms ease;
  width: 215px;
  z-index: 1;

  &.collapsed {
    width: 66px !important;
    transition: width 200ms ease;
    overflow: inherit;
    padding-top: 60px;
  }
}

.mainMenu {
  padding: 23px;
  width: 100%;
}

.mainMenuCollapsed {
  padding: 12px;
}

.menuIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-bottom: 10px;
  transition: all 200ms ease;
  width: 34px;
  color: white;
  text-decoration: none;
}

.subMenu {
  background-image: var(--navbar-submenu-bg);
  bottom: 0;
  padding: 23px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &.isMobile {
    overflow-y: auto;
  }

  &.subMenuCollapsed {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 60px;
  }

  @include media-breakpoint-only(xs) {
    padding-bottom: 80px;
  }

  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--custom-toolbar-shadow);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--custom-toolbar-background);
    -webkit-box-shadow: inset 0 0 6px var(--custom-toolbar-background-thumb);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: var(--custom-toolbar-background-inactive);
  }
}

.subMenu + .mainMenu {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 1000ms ease;
  width: 100%;
  background: var(--navbar-background);
  transform: translate(-215px, 0);
  z-index: 2;

  &.mainMenuCollapsed {
    padding-top: 60px;
    //transform: translate(-70px, 0);
  }

  &.mainMenuOver {
    transition: transform 300ms ease;
    transform: translate(0, 0) !important;
  }
}

.menuIconMobile {
  color: white;
  cursor: pointer;
  display: none;
  left: 10px;
  position: absolute;
  top: 20px;
  z-index: 3;
  justify-content: space-between;

  @include media-breakpoint-only(xs) {
    display: block;
  }

  &.menuIconMobileShowTopMsg {
    top: 80px;

    @include media-breakpoint-only(xs) {
      top: 72px;
    }
  }
}

.nav-bar-hidden {
  .menuIconMobile {
    display: none;
  }

  .outspreadBackground {
    left: 0;
    display: block;
  }
}

.outspreadBackground {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1999;
  left: -100vw;
}

.collapseIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  &.collapseIconCollapsed {
    border-bottom: 1px solid var(--navbar-collapsed-border);
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    right: 10px;
    width: 40px;
  }
}

.collapseIcon {
  cursor: pointer;
  opacity: 0.15;
  transition: opacity 200ms ease, transform 200ms ease;

  .collapseIconCollapsed & {
    transform: rotate(180deg);
    transition: opacity 200ms ease, transform 200ms ease;
  }

  &:hover {
    opacity: 1;
    transition: opacity 200ms ease, transform 200ms ease;
  }
}

.dockScrollerHover {
  width: 190px;
  background-color: rgba(0, 0, 0, 0.2);
}
