@import 'styles/mixings.scss';

.loading {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 30px;
}

.orderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.sorter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.order {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .labelSortBy {
    color: var(--main-text-color-alt);
    margin-right: 10px;
  }

  svg {
    margin-left: 5px;
    width: 20px;
  }
}

.orderBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--element-background-odd);
  border-radius: 50px;
  padding: 3px 5px 3px 15px;
  min-width: 105px;
}

.orderButton {
  cursor: pointer;
  margin-left: 10px;
}

.dateFilter {
  width: 100%;
}

.historyContainer {
}
