@import '../../styles/mixings.scss';

.container {
  flex: 1;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.downloadContainer {
  color: var(--main-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 25px;
}

.subtitle {
  margin-bottom: 20px;
  margin-top: 5px;
}

.button {
  margin-bottom: 15px;
}

.goToTrustButton {
  margin-top: 30px;
  margin-bottom: 15px;
}
