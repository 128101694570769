@import '../../../styles/mixings.scss';

.selectPermissions {
  color: var(--main-text-color);
  background-color: transparent;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px 5px 10px;
  height: 350px;
  width: 100%;

  @include media-breakpoint-only(xs) {
    height: 200px;
  }
}

.selectPermissions:focus {
  outline: none;
}

.error {
  border-color: var(--signin-error-color);
}

.errorMsg {
  margin-top: 5px;
  color: var(--signin-error-color);
}
