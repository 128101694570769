@import '../../styles/mixings.scss';

.statusContainer {
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  width: 100%;
  min-width: 95px;

  @include media-breakpoint-only(xs) {
    margin-top: 10px;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  height: 12px;
  margin-right: 10px;
  width: 12px;
}

.statusVoting {
  background: var(--status-voting);
}

.statusFinish {
  background: var(--status-finish);
}

.statusRejected {
  background: var(--status-rejected);
}

.statusCancel {
  background: var(--status-cancel);
}

.statusResolved {
  background: var(--status-resolved);
}

.statusApproved {
  background: var(--status-approved);
}
