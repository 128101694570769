@import '../styles/mixings.scss';

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--main-text-color);
  font-size: 16px;
  margin-bottom: 20px;
}

.rowBg {
  background: var(--element-background);
  border-radius: 5px;
  transition: background-color 200ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
    transition: background-color 200ms ease;
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background-color 200ms ease;
  }
}

.rowTransition {
}

.rowTransition-enter {
  opacity: 0;
}

.rowTransition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.rowTransition-exit {
  opacity: 1;
}

.rowTransition-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.tableRowGrid {
  display: grid;
  grid-gap: 10px;
}
