.fieldList {
  overflow-y: auto;
}

.changeContainer {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'field field'
    'currentValue newValue';
  margin-bottom: 15px;
}

.field {
  grid-area: field;
  font-size: 14px;
  font-weight: bold;
}

.currentValue {
  grid-area: currentValue;
  text-align: left;
}

.newValue {
  grid-area: newValue;
  text-align: left;
}
