@import '../../styles/mixings.scss';

.wrapper {
  grid-column: span 2;

  @include media-breakpoint-only(sm) {
    grid-column: span 1;
  }

  @include media-breakpoint-only(xs) {
    grid-column: span 1;
  }
}
