@import '../../../styles/mixings.scss';

.changePassword {
  display: flex;
  flex-direction: column;
}

.container {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  width: 100%;
  margin-bottom: 20px;

  @include media-breakpoint-only(sm) {
    grid-area: auto / span 2;
  }

  @include media-breakpoint-only(xs) {
    grid-area: auto / span 2;
  }
}

.actionsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-end;
    bottom: 0;
    height: 50px;
    justify-content: center;
    position: relative;
    right: 0;
    top: 30px;
  }
}

.saveButton {
  margin-right: 20px;
}

.keysWarning {
  color: var(--main-color);
  font-style: italic;
  margin-bottom: 20px;
}

.passwordRules {
  align-self: start;
  color: var(--main-text-color-alt);
  display: flex;
  margin-left: 100px;
  margin-top: 100px;
  flex-direction: column;

  @include media-breakpoint-only(sm) {
    display: none;
  }

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.rulesHeader {
  font-size: 24px;
  margin-bottom: 10px;
}

.ruleDescription {
  display: flex;

  svg {
    margin-right: 5px;
  }
}
