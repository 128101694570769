@import 'styles/mixings';

.containerTotalCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr 1fr;
  }
}

.containerLists {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

.lastTrust {
  @include media-breakpoint-only(xs) {
    margin-top: 12px;
    grid-row: 2;
  }
}

.link {
  text-decoration: none;
}

.lastTransactions {
  @include media-breakpoint-only(xs) {
    grid-row: 1;
  }
}

.notResult {
  display: flex;
  margin-top: 10px;
  align-items: center;
  text-align: center;
  background: var(--element-background);
  border-radius: 5px;
  width: 100%;
  color: var(--main-text-color);
  padding: 18px 20px 18px 30px;
  text-decoration: none;
  transition: all 500ms ease;
  grid-column: 2;

  @include media-breakpoint-only(sm) {
    height: 105px;
  }

  @include media-breakpoint-only(xs) {
    height: 105px;
  }

  &.trustNotResult {
    height: 206px;
  }

  &.transactionsNotResult {
    height: 206px;

    @include media-breakpoint-only(xs) {
      height: 97px;
    }
  }

  &.financialStructuresNotResult {
    height: 97px;
  }
}

.lastCorporation {
  margin-top: 22px;

  @include media-breakpoint-only(xs) {
    grid-row: 3;
  }
}

.termsAndConditions {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  height: 250px;
  padding: 18px;
  overflow-y: scroll;

  p {
    margin-top: 20px;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  h3 {
    text-transform: none;
  }

  /* width */
  &::-webkit-scrollbar {
    color: white;
    cursor: pointer;
    border-radius: 11px;
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 11px;
    background: #888;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 11px;
  }
}
