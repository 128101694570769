.firstLevelError {
  padding-top: 20px;
  display: flex;
}

.alertIcon {
  color: var(--signin-error-color);
}

.text {
  margin-left: 15px;
  color: var(--signin-error-color);
}
