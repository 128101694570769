@import '../../styles/mixings.scss';

.clientTableItemStructureName {
  align-items: flex-start;
  color: var(--element-main-color);
  display: flex;
  font-size: 14px;
  grid-area: structureName;
  text-transform: capitalize;
}

.clientTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
}

.clientTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
}

.clientTableItemStatus {
  grid-area: status;
}

/*.clientTableItemActions {
  grid-area: actions;
}*/

.clientTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  grid-template: repeat(3, 1fr) / 0.8fr 1fr 100px;
  grid-template-areas:
    'itemName actions status'
    'structureName actions status'
    'updatedAt actions status';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 5px;

    grid-template-areas:
      'itemName itemName'
      'structureName structureName'
      'updatedAt updatedAt'
      'status status';
  }
}

.clientTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 5px;
  grid-template-areas:
    'itemName itemName'
    'structureName structureName'
    'updatedAt updatedAt'
    'status status';
  padding: 30px;

  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  & .clientTableItemActions {
    justify-self: end;
  }

  .clientTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  & .clientTableItemStatus {
    margin-top: 10px;
  }

  & .clientTableItemActions {
    margin-top: 10px;
  }
}

.clientTableItem--list {
  grid-template-columns: 0.6fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;

  & .clientTableItemStatus {
    min-width: 100px;
  }

  & .clientTableItemActions {
    min-width: 100px;
  }

  .clientTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.clientTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 1fr 1fr 1fr 1fr 130px;
  grid-template-rows: 1fr;
  grid-template-areas: 'itemName structureName createdAt updatedAt status';
  padding: 20px 10px;

  & .clientTableItemName,
  & .clientTableItemCreatedAt,
  & .clientTableItemUpdatedAt,
  & .clientTableItemSettlorName {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  & .clientTableItemActions {
    padding-left: 20px;
  }

  .clientTableItemCreatedAt {
    display: flex;
  }

  .typeIcon {
    position: relative;
    top: 3px;
  }
}
