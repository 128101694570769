.item {
  align-items: center;
  border-bottom: 1px solid var(--navbar-collapsed-border);
  color: var(--main-text-color-alt);
  display: flex;
  font-size: 18px;
  height: 53px;

  &:last-child {
    border-bottom: none;
  }

  &:not(.disabled) {
    cursor: pointer;
    transition: color 200ms ease;
  }

  &:not(.disabled):hover {
    color: var(--main-text-color);
    transition: color 200ms ease;
  }
}

.icon {
  display: block;
  margin-right: 10px;
}

.selected {
  color: var(--main-text-color);

  svg {
    fill: var(--checked-color);
  }
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
