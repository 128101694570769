@import '../../styles/mixings.scss';

.input {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--inputs-border-color);
  border-radius: 20px;
  color: var(--main-text-color);
  font-family: verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  outline: none;
  padding: 10px 25px;
  resize: none;
  transition: all 200ms ease;
  width: 100%;

  &::placeholder {
    color: var(--inputs-placeholder-color);
    transition: all 200ms ease;
  }

  &:disabled {
    background: var(--inputs-disabled-bg);

    &::placeholder {
      color: var(--inputs-disabled-placeholder);
      transition: all 200ms ease;
    }
  }

  &:focus {
    border: 1px solid var(--main-color);
    color: var(--main-color);
    transition: all 200ms ease;

    &::placeholder {
      color: var(--main-color-alt) !important;
      transition: all 200ms ease;
    }
  }

  &.fullHeightArea {
    flex: 1;
  }

  &.error {
    border-color: var(--signin-error-color);
    color: var(--signin-error-color);
  }
}

.fullHeightLabel {
  @include media-breakpoint-only(xs) {
    flex: 1;
    flex-direction: column;
    display: flex;
  }
}
