@import '../../styles/mixings.scss';

.publishingModal {
  align-items: center;
  background: var(--sidemodal-overlay-animation);
  color: #ffffff;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.publishTrustLoaderContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.connectingToServer {
  align-items: center;
  background: #423f42;
  border-radius: 100px;
  color: #ffffff;
  display: flex;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  transform: translateY(-50%);
  transition: opacity 200ms ease;
  max-width: 500px;
  margin-top: 50px;
}

.loadingBar {
  background: #231f23;
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 0;
}

.statusTexts {
  height: 40px;
  position: relative;
  text-align: center;
  width: 100%;
}

.statusText {
  left: 0;
  opacity: 0;
  position: absolute;
  top: -15px;
  width: 100%;
}

.check {
  opacity: 0;
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

.goBackButton {
  opacity: 0;
}

.nacho {
  opacity: 0;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
}

.lockContainer {
  margin-top: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.lock {
  top: -498px;
  opacity: 0;
  position: absolute;
  width: 280px;

  @media (max-width: $sm) {
    top: -378px;
    opacity: 0;
    position: absolute;
    width: 160px;
  }
}

.security {
  margin-left: -450px;
  top: -157px;
  opacity: 0;
  position: absolute;
  width: 157px;

  @media (max-width: $sm) {
    margin-left: -310px;
    top: -112px;
    opacity: 0;
    position: absolute;
    width: 85px;
  }
}

.server {
  top: -242px;
  margin-left: 480px;
  opacity: 0;
  position: absolute;
  width: 138px;

  @media (max-width: $sm) {
    top: -170px;
    margin-left: 315px;
    opacity: 0;
    position: absolute;
    width: 80px;
  }
}

/* variables de color para checkmarck green */
$green: #7ac142;
$white: #fff;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  top: -104px;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 $green;
  animation: fill 0s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;

  @media (max-width: $sm) {
    top: -98px;
    width: 48px;
    height: 48px;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #689f38;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #689f38;
  }
}

.displayNone {
  display: none;
}

.succesfully {
  color: var(--main-text-color);
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  top: 136px;
  opacity: 0;
  position: absolute;

  @media (max-width: $sm) {
    top: 75px;
    font-size: 24px;
  }
}

.buttonSuccesfully {
  opacity: 0;
  top: 187px;

  @media (max-width: $sm) {
    top: 124px;
  }
}
