.linkButton {
  text-align: center;
  color: var(--element-main-color);
  font-size: 12px;
  margin-top: 18px;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--main-text-color);
  }
}

.icon {
  margin-left: 5px;
}
