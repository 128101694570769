.pageWrapper {
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
}

.notFoundWrapper {
  border-radius: 5px;
  color: var(--main-text-color-alt);
  fill: var(--main-text-color);
  height: 500px;
  text-align: center;
  width: 100%;
}

.title {
  font-size: 25px;
}

.desc {
  font-size: 17px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.button {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
