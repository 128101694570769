.accountsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;
}

.title {
  margin: 16px 0;
  text-align: center;
  width: 100%;
}

.sectionContainer {
  margin-bottom: 20px;
}
