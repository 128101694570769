@import '../../styles/mixings.scss';

.tabsContainer {
  margin-top: 20px;
}

.trustInformation {
  flex-direction: column;
}

.trustInformation__content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 15px;
  }
}

.trustInformation__YesNoToggle {
  margin-top: 40px;

  @include media-breakpoint-only(xs) {
    margin-top: 25px;
  }
}
