@import '../../styles/mixings.scss';

.trustDocumentArchiveContainer {
  display: flex;
  position: relative;
}

.connectionLine {
  border-left: 1px solid var(--history-connector);
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  width: 1px;

  &.first {
    top: 20px;
  }

  &.last {
    bottom: auto;
    height: 20px;
  }
}

.bulletContainer {
  width: 40px;
  padding-top: 15px;
  position: relative;

  @include media-breakpoint-only(xs) {
    width: 30px;
  }
}

.bullet {
  align-items: center;
  background-color: var(--history-bullet-inactive);
  border-radius: 100px;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;

  &.active {
    background-color: var(--history-bullet-active);
  }

  @include media-breakpoint-only(xs) {
    height: 18px;
    width: 18px;
  }
}

.bulletInner {
  background-color: var(--history-bullet-inner-circle);
  border-radius: 100px;
  height: 8px;
  width: 8px;

  @include media-breakpoint-only(xs) {
    height: 6px;
    width: 6px;
  }
}

.itemContainer {
  flex: 1;
}
