@import '../../styles/mixings.scss';

.header {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.secondaryNavbarLogo {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-top: 7px;
  text-transform: uppercase;

  @include cut-text-with-ellipsis;
}
