@import 'styles/mixings';

.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-top: 15px;
}

.formContainerTwoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 15px;
}

.containerActionButtons {
  width: 90%;
  bottom: 30px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}

.actions {
  display: flex;
  font-style: italic;
  padding-top: 15px;

  @include media-breakpoint-only(xs) {
    padding-top: 0;
    min-width: 100%;
  }
}

.votersCanAbstain {
  @include media-breakpoint-only(lg) {
    width: 50%;
  }
}

.storedDataContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.storedData {
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
  }
}

.visibleStoredData {
  display: grid;
  opacity: 1;
}
