@import 'styles/mixings.scss';

.signInContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-height: 100vh;
}

.signInBackground {
  background-image: url(./images/bg.png);
  background-size: cover;
  background-position: center center;
  flex: 1;
}

.logo {
  align-content: center;
  color: var(--signin-logo);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 18px;
  height: 33px;
  justify-content: center;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.logoBrand {
  text-align: center;
  margin-top: 13px;
}

.signInBox {
  background-image: var(--signin-box-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
  width: 34vw;

  @include media-breakpoint-only(md) {
    width: 500px;
  }

  @include media-breakpoint-only(sm) {
    width: 450px;
  }

  @include media-breakpoint-only(xs) {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }
}

.signInForm {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.containerCheckbox {
  margin-left: 14px;
  margin-top: 16px;
}

.errorBox {
  color: var(--signin-error-color);
  font-size: 16px;
  margin-bottom: 30px;
}
