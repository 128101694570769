@import 'styles/mixings.scss';

.rulesList {
  height: calc(100vh - 75px);
  overflow: auto;
  padding-right: 7px;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  margin-bottom: -105px;
  padding-bottom: 5px;

  @include media-breakpoint-only(sm) {
    height: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
  }

  @include media-breakpoint-only(xs) {
    height: 100%;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
  }

  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--custom-toolbar-shadow);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--custom-toolbar-background);
    -webkit-box-shadow: inset 0 0 6px var(--custom-toolbar-background-thumb);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: var(--custom-toolbar-background-inactive);
  }
}
