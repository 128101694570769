@import '../../styles/mixings.scss';

.container {
  background: var(--element-background);
  margin-bottom: 20px;
  padding: 20px;
  opacity: 0;
  transition: opacity 500ms ease;
}

.errorContainer {
  align-items: end;
  color: var(--signin-error-color);
  display: grid;
  grid-template-columns: 2fr 1fr 40px;
  font-size: 15px;
  grid-template-areas: 'a error b';
  grid-column-gap: 40px;
  margin-top: 5px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr 1fr 40px;
    grid-row-gap: 15px;

    > :nth-child(1) {
      grid-column: 1 / span 3;
    }

    > :nth-child(2) {
      grid-column: 1 / span 2;
    }
  }

  svg {
    position: relative;
    top: 3px;
    margin-right: 5px;
  }
}

.error {
  grid-area: error;
}

.show {
  opacity: 1;
}
