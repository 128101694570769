@import '../../styles/mixings.scss';

.subtitle {
  color: var(--main-color);
  font-family: 'Verdana';
  text-transform: uppercase;
  font-size: 13px;
  order: -1;
  margin: 0;
  padding: 0;

  @include media-breakpoint-only(xs) {
    font-size: 15px;
    order: 0;
    padding: 15px 0;
  }
}
