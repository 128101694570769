@import '../../styles/mixings.scss';

.cropperContainerInner {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
}

.resultContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: var(--main-text-color);
  justify-content: center;
  align-items: center;

  @include media-breakpoint-only(xs) {
    order: -1;
    margin-bottom: 15px;
  }
}

.actionsButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.sectionTitle {
  margin-bottom: 15px;
}

.preview {
  border-radius: 50%;
  height: 100px;
  overflow: hidden;
  width: 100px;
}

.rotateIcon {
  position: absolute;
  left: 20px;
  top: 10px;
}
