.toolTip {
  font-style: italic;
  font-size: 13px;
  padding: 4px 10px;
  background-color: var(--primary-tooltip-background) !important;

  &.toolTipTop {
    :global {
      .tippy-arrow {
        border-top-color: var(--primary-tooltip-background) !important;
      }
    }
  }

  &.toolTipRight {
    :global {
      .tippy-arrow {
        border-right-color: var(--primary-tooltip-background) !important;
      }
    }
  }

  &.toolTipLeft {
    :global {
      .tippy-arrow {
        border-left-color: var(--primary-tooltip-background) !important;
      }
    }
  }

  &.toolTipBottom {
    :global {
      .tippy-arrow {
        border-bottom-color: var(--primary-tooltip-background) !important;
      }
    }
  }

  &.toolTipHidden {
    display: none;
  }

  &.toolTipSecondary {
    background-color: var(--secondary-tooltip-background) !important;

    &.toolTipTop {
      :global {
        .tippy-arrow {
          border-top-color: var(--secondary-tooltip-background) !important;
        }
      }
    }

    &.toolTipRight {
      :global {
        .tippy-arrow {
          border-right-color: var(--secondary-tooltip-background) !important;
        }
      }
    }

    &.toolTipLeft {
      :global {
        .tippy-arrow {
          border-left-color: var(--secondary-tooltip-background) !important;
        }
      }
    }

    &.toolTipBottom {
      :global {
        .tippy-arrow {
          border-bottom-color: var(--secondary-tooltip-background) !important;
        }
      }
    }
  }
}
