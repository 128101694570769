@import '../../../styles/mixings.scss';

.button {
  align-items: center;
  border: 1px solid var(--history-bullet-inner-circle);
  border-radius: 50px;
  display: flex;
  font-size: 13px;
  height: 30px;
  padding-left: 15px;
  text-transform: uppercase;
  padding-right: 15px;
  transition: background 200ms ease, color 200ms ease;

  svg {
    margin-right: 7px;
  }

  &:hover {
    background: white;
    color: var(--secondary-color);
    transition: background 200ms ease, color 200ms ease;
  }

  @include media-breakpoint-only(xs) {
    height: 25px;
    margin-left: 10px;
  }
}
