@import 'styles/mixings';

.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-top: 30px;
  max-height: 65px;
}

.formContainerTwoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 15px;
  max-height: 65px;
}

.containerComplexResolution {
  margin-top: 30px;
}

.errorMessageModal {
  color: var(--signin-error-color);
}
