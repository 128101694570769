@import '../../styles/mixings.scss';

.wrapper {
  display: flex;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.main {
  color: var(--main-text-color);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 45px 80px;
  position: relative;

  @include media-breakpoint-only(md) {
    padding: 20px 40px;
    min-height: 100vh;
  }

  @include media-breakpoint-only(sm) {
    padding: 20px 20px;
    min-height: 100vh;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px 15px;
    min-height: 100vh;
    z-index: unset;
  }
}
