@import '../../styles/mixings.scss';

.andXMore {
  text-transform: none;
  white-space: nowrap;
}

.iconStructureType {
  margin-right: 5px;
  min-width: 20px;
}

.companyName {
  max-width: 1220px;
  white-space: unset !important;
  overflow: initial !important;
}

.noTextTransform {
  text-transform: none;
}

.dottedUnderline {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color) !important;
  width: fit-content;
}
