@import '../../styles/mixings.scss';

.wrapper {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  padding: 25px 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-only(xs) {
    padding: 20px 25px;
  }
}

.documentData {
  display: grid;
  overflow: hidden;
}

.name {
  font-size: 20px;
  font-family: var(--mainFont);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentTitle {
  color: var(--primary-btn-alt-color-2);
  font-size: 14px;
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.fileName {
  margin-right: 15px;

  @include media-breakpoint-only(xs) {
    display: none;
  }
}
