@import 'styles/mixings';

.containerVoters {
  padding-top: 12px;
  padding-bottom: 20px;
}

.viewVoters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 15px;
}
