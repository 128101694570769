@import '../../styles/mixings.scss';

.entityTitle {
  color: var(--main-text-color);
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding-left: 9px;
  padding-right: 9px;
  text-transform: uppercase;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include media-breakpoint-only(md) {
    margin-top: 15px;
  }

  .subMenuCollapsed & {
    display: none;
  }
}

.entityLastSave {
  color: var(--main-text-color-alt);
  font-size: 13px;
  margin-bottom: 20px;
  padding-left: 9px;
  padding-right: 9px;

  .lastSaveDate {
    text-transform: capitalize;
  }

  .subMenuCollapsed & {
    display: none;
  }
}

.status {
  margin-bottom: 20px;
  padding-left: 9px;
  padding-right: 9px;

  &.statusCollapsed {
    display: flex;
    justify-content: center;
  }
}

.subMenuButtonsContainer {
  position: relative;
  transition: opacity 200ms ease;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  &.subMenuDisabled {
    opacity: 0.3;
    transition: opacity 200ms ease;

    &:after {
      bottom: 0;
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  .subMenuCollapsed & {
    display: flex;
    flex-direction: column;
  }
}
