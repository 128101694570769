@import '../../styles/mixings.scss';

.subtitle {
  color: var(--dynamicformsubtitle-color);
  font-size: 16px;
  margin-bottom: 20px;
}

.separateTop {
  margin-top: 50px;

  @include media-breakpoint-only(xs) {
    margin-top: 0;
  }
}
