@import '../../styles/mixings.scss';

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  position: relative;
  height: 85px;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease;
  width: 85px;

  &:hover {
    svg {
      color: var(--button-default-background-hover);
      transition: color 200ms ease;
    }
  }

  @include media-breakpoint-only(xs) {
    height: 85px;
    width: 85px;
  }
}

.literalImage {
  cursor: initial;
}

.loadingImage {
  filter: blur(1px) grayscale(100%);
  -webkit-filter: blur(1px) grayscale(100%);
}

.dragginOverTarget {
  .dropHere {
    display: flex;
    opacity: 1;
    transition: all 200ms ease;
  }
}

.dropHere {
  background: rgba(14, 46, 63, 0.5) !important;
  display: none;
  opacity: 0;
  margin-top: 0;
  min-width: 100%;
  border-radius: 50%;
  min-height: 100%;
  position: absolute;
  transition: all 200ms ease;
  text-transform: uppercase;
}

.error {
  color: var(--signin-error-color);
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  max-width: 200px;

  @include media-breakpoint-only(xs) {
    max-width: initial;
  }
}

.fileDrop {
  position: relative;
}

.loadingSaveImage {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -9px;
  left: -9px;
  z-index: 1;
}

.loadingSaveImage div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 85px;
  height: 85px;
  margin: 8px;
  border: 4px solid var(--main-text-color);
  border-radius: 50%;
  animation: loadingSaveImage 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--main-text-color) transparent transparent transparent;
}
.loadingSaveImage div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingSaveImage div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingSaveImage div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingSaveImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.changeImageButton {
  margin-top: 15px;
  height: 30px !important;
  padding: 0px 30px;
}

.remove {
  margin-top: 10px;
  font-size: 14px;
  text-decoration: underline;
  color: var(--tables-title-color);
  cursor: pointer;
  transition: color 200ms ease;

  &:hover {
    color: var(--main-text-color-alt);
    transition: color 200ms ease;
  }
}
