.container {
  border-top: 1px dashed var(--inputs-border-color);
  color: var(--main-color);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 0 30px;
  padding-top: 35px;
  text-transform: capitalize;
}

.title {
  display: flex;
  text-transform: uppercase;

  svg {
    margin-right: 10px;
  }
}
