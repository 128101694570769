.elementRow {
  background: var(--element-background);
  margin-bottom: 10px;

  &:hover {
    background: var(--element-background-hover);
    transition: background-color 200ms ease;
  }
}

.error {
  color: var(--signin-error-color);
}

.transactionTypeSelection {
  margin-bottom: 15px;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.actionButtons {
  align-self: flex-end;
  bottom: 15px;
  position: fixed;

  display: flex;
}

.errorContainer {
  display: flex;
  flex-direction: column;
}

.nextButton {
  &:disabled {
    opacity: 0.25;
  }
}