@import 'styles/mixings';

.containerCount {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 12px;
}

.icon {
  margin-top: 6px;
}

.iconDraft {
  color: var(--status-stored);
}

.iconExecution {
  color: var(--signin-error-color);
}

.iconStored {
  color: var(--signin-error-color);
}

.card {
  cursor: pointer;
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  width: 100%;
  height: 90px;
  color: var(--main-text-color);
  padding: 15px 20px;
  text-decoration: none;
  transition: all 500ms ease;

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(lg) {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 18px 22px;
  }

  @include media-breakpoint-only(xs) {
    padding: 13px 0 0 22px;
  }
}

.totalCount {
  font-family: var(--mainFont);
  font-size: 30px;
  color: white;
}

.statusContainer {
  margin-top: 2px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 25px;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;
  min-width: 95px;
  flex: 3;

  @include media-breakpoint-only(md) {
    justify-content: flex-start;
    margin-right: 0;
  }

  @include media-breakpoint-only(sm) {
    justify-content: flex-start;
    margin-right: 0;
  }

  @include media-breakpoint-only(xs) {
    margin-top: 10px;
    justify-content: flex-start;
    margin-right: 0;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
    color: var(--main-text-color-alt);
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  margin-right: 10px;
  height: 10px;
  width: 10px;
}

.status {
  flex: 1;
}

.statusExecution {
  background: var(--status-voting);
}

.statusDraft {
  background: var(--status-draft);
}

.statusExecuted {
  background: var(--status-finish);
}

.statusPending {
  background: var(--status-pending);
}

.statusStored {
  background: var(--status-approved);
}

.storedPoint {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin-left: 5px;
  align-self: flex-start;
  background-color: var(--main-text-color);
}
