.iconPartyType {
  margin-right: 5px;
  min-width: 20px;
}

.andXMore {
  text-transform: none;
  white-space: nowrap;
}

.dottedUnderline {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color);
  width: fit-content;
}

.noTextTransform {
  text-transform: none;
}
