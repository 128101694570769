.containerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-text-color);
  width: 52px;
  height: 53px;
  border-radius: 100px;
  background-color: #65a64b;
  margin-bottom: 20px;
  margin-top: 25px;
}

.successBox {
  color: var(--main-text-color);
  font-size: 20px;
  margin-bottom: 8px;
  text-align: center;
}

.successBoxSecondLine {
  color: var(--main-text-color);
  font-size: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.success {
  align-items: center;
  display: flex;
  flex-direction: column;
}
