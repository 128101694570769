@import 'styles/mixings';

.option {
  justify-content: space-between;
  align-items: center;
  color: var(--default-text-color);
  display: flex;
  padding: 10px 45px 10px 40px;
  border-bottom: 1px solid #ededed;

  &:hover,
  &.focus {
    background: var(--button-default-background-hover);
  }

  @include media-breakpoint-only(xs) {
    align-items: center;
    padding: 10px 20px 10px 15px;
  }
}

.imageContainer {
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.name {
  font-size: 14px;

  @include media-breakpoint-only(xs) {
    font-size: 12px;
    word-break: break-all;
  }
}

.email {
  font-size: 14px;
  @include media-breakpoint-only(xs) {
    font-size: 12px;
    word-break: break-all;
  }
}

.imageContainer {
  position: relative;
}

.optionImage {
  border: 1px solid var(--main-text-color-alt);
  margin-right: 20px;
  border-radius: 100px;
  position: relative;
  height: 45px;
  width: 45px;
  background-color: var(--secondary-color);
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loadingImage {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.assignedUsersContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 15px;
  & > div {
    width: 100%;
  }
  & div {
    cursor: default;
  }
}

.buttonsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-end;
    bottom: 0;
    height: 50px;
    justify-content: center;
    position: relative;
    right: 0;
    top: 30px;
  }
}

.loadingSave {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -9px;
  left: -9px;
  z-index: 1;
}

.loadingSave div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 47px;
  height: 47px;
  margin: 8px;
  border: 3px solid var(--main-text-color);
  border-radius: 50%;
  animation: loadingSave 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--button-default-color) transparent transparent transparent;
}
.loadingSave div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingSave div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingSave div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingSave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
