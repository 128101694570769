@import 'styles/mixings.scss';

.trustTableItemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trustTableItemSettlorName {
  align-items: flex-start;
  color: var(--element-main-color);
  display: flex;
  font-size: 14px;
  grid-area: settlorsName;
  text-transform: capitalize;

  @include media-breakpoint-only(xs) {
    display: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.trustTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
  align-items: center;
}

.trustTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
  align-items: center;
}

.trustTableItemStatus {
  grid-area: status;
}

.trustTableItemActions {
  grid-area: actions;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: fit-content;
}

.trustTableItemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trustTableItemSettlorName {
  align-items: flex-start;
  color: var(--element-main-color);
  display: flex;
  font-size: 14px;
  grid-area: settlorsName;
  text-transform: capitalize;
}

.trustTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
}

.trustTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
}

.trustTableItemStatus {
  grid-area: status;
}

.trustTableItemActions {
  grid-area: actions;
  margin-top: 5px;
}

.trustTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  /*cursor: pointer;*/
  display: grid;
  grid-template: repeat(3, 1fr) / 0.8fr 1fr 100px;
  grid-template-areas:
    'itemName actions status'
    'settlorsName actions status'
    'updatedAt actions status';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
    grid-template: repeat(5, 1fr) / 1fr 100px;

    grid-template-areas:
      'itemName itemName'
      'settlorsName settlorsName'
      'updatedAt updatedAt'
      'status status'
      'actions actions';
  }
}

.trustTableItem--list {
  grid-template-columns: 0.6fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;

  & .trustTableItemStatus {
    min-width: 100px;
  }

  & .trustTableItemActions {
    @include media-breakpoint-only(xs) {
      width: 50%;
    }
  }

  .trustTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
    grid-column-end: 3;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;

    & .trustTableItemActions {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .actionButton {
      border: solid 0.8px var(--bold-color);
      width: 49%;
      margin-top: 5px;
    }
  }

  .actionButton {
    border: solid 0.8px var(--bold-color);
    height: 26px !important;
  }
}

.trustTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr 100px;
  grid-template-areas:
    'itemName itemName'
    'settlorsName settlorsName'
    'updatedAt updatedAt'
    'status actions';
  padding: 25px 25px 25px 30px;

  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  & .trustTableItemActions {
    justify-self: end;
  }

  .trustTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  & .trustTableItemStatus {
    margin-top: 10px;
  }

  .actionButton {
    border: solid 0.8px var(--bold-color);
    height: 30px !important;
    width: 30px !important;
  }
}

.trustTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'itemName settlorsName createdAt updatedAt status actions';
  padding: 20px 10px;

  & .trustTableItemName,
  & .trustTableItemCreatedAt,
  & .trustTableItemUpdatedAt,
  & .trustTableItemSettlorName {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  .trustTableItemCreatedAt {
    display: flex;
  }
}

.trustTableItem--detail.noActions {
  grid-template-columns: 1fr 1fr 1fr 1fr 130px;
}

.isNotClickable {
  cursor: default;
}

.isClickable {
  cursor: pointer;
}
