@import 'styles/mixings.scss';

.actionButtons {
  z-index: 11;
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;

  @include media-breakpoint-only(xs) {
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    bottom: 12px;
    justify-content: center;
  }
}

.fullPage {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
}

.rulesSectionContainer {
  display: flex;

  @include media-breakpoint-only(sm) {
    flex-direction: column;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
}

.rules {
  margin-right: 15px;
  position: relative;
  width: 328px;

  @include media-breakpoint-only(md) {
    width: 250px;
  }

  @include media-breakpoint-only(sm) {
    margin-right: 0;
    width: 100%;
    max-width: calc(100vw - 25px);
  }

  @include media-breakpoint-only(xs) {
    margin-right: 0;
    width: 100%;
    max-width: calc(100vw - 25px);
  }
}

.text {
  background: var(--main-text-color);
  border-radius: 5px;
  color: var(--button-default-color);
  font-size: 16px;
  flex: 1;
}

.sectionSeparator {
  border-color: var(--element-background);
  margin-bottom: 40px;
  margin-top: 20px;
}
