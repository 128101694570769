@import '../../styles/mixings.scss';

.assetTypeSelect {
  margin-bottom: 15px;
}

.tabsContainer {
  margin-top: 20px;
}

.companyInformation {
  flex-direction: column;
}

.companyInformation__content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 15px;
  }
}
