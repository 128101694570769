@import 'styles/mixings.scss';

.inputsContainer {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 10px;
}

.manageAssetsButton {
  margin-top: 20px;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 15px;

  @include media-breakpoint-only(md) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-only(xs) {
    grid-template-columns: 1fr 1fr;
  }
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
}

.linkButton {
  border: none;
  background: none;
  color: var(--main-text-color-alt);
  text-decoration: underline;
  margin-top: 10px;
}
