.checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 14px;
  margin-top: 15px;
}

.checkmark {
  cursor: pointer;
  display: none;
  position: absolute;
  left: 53px;
  color: var(--main-text-color);

  &.show {
    display: flex;
  }
}

.containerInput {
  position: relative;
}

.label {
  color: #6d6d72;
  font-size: 13px;
  margin-left: 10px;

  &.error {
    color: var(--signin-error-color);
  }
}

.containerCheckboc {
  display: flex;
  align-items: center;
}

.input {
  cursor: pointer;
  appearance: none;
  border: 1px solid #6d6d72;
  border-radius: 4px;
  color: var(--main-text-color);
  outline: none;
  height: 20px;
  width: 20px;
  transition: all 200ms ease;

  &:disabled {
    opacity: 0.3;
    transition: all 200ms ease;
  }

  &:checked {
    background-color: var(--status-finish);
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }
}

.errorMessage {
  color: var(--signin-error-color);
  font-size: 14px;
  margin-left: 15px;
  margin-top: 5px;
}

.iconInput {
  position: absolute;
  top: 38px;
  left: 14px;
  color: var(--main-text-color);
}
