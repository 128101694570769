.literalValueContainer {
  margin-bottom: 16px;
  width: 100%;
}

.detailsContainer {
  margin: 16px 0px;
  font-size: 16px;
  width: 100%;
  text-align: center;
}
