.containerSnapshotItem {
  display: inline-block;
}

.containerViewMode {
  border-bottom: 1px solid var(--settlor-display-border-color);
}

.name {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 7px;
}

.icon {
  margin-right: 8px;
}
