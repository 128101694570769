.container {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  animation: appearSliding 0.3s ease-in-out;
}

.statusContainer {
  width: 100%;
  margin-bottom: 16px;
}

.dateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--main-text-color-alt);
}

.dateInputWrapper {
  margin: 0 10px 0 10px;
}

.changeTypeContainer {
  margin-bottom: 16px;
}

.isConfirmedContainer {
  margin-bottom: 16px;
}

.closeButton {
  align-self: flex-end;
}

@keyframes appearSliding {
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}
