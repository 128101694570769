.wrapper {
  max-height: 100%;

  &:focus {
    outline: none !important;
  }
}

.inner {
  transform: translate(0, 0);
}
