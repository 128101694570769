@import '../../styles/mixings.scss';

.itemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.roleTableItemCPartiesName {
  align-items: flex-start;
  color: var(--element-main-color);
  display: flex;
  font-size: 14px;
  grid-area: cpartiesName;
  text-transform: capitalize;
}

.roleTableItemCreatedAt {
  color: var(--element-main-color);
  display: none;
  font-size: 14px;
  grid-area: createdAt;
}

.roleTableItemUpdatedAt {
  color: var(--element-main-color);
  font-size: 14px;
  grid-area: updatedAt;
}

.roleTableItem {
  align-items: center;
  background: var(--element-background);
  border-radius: 5px;
  color: var(--main-text-color);
  cursor: pointer;
  display: grid;
  grid-template: repeat(3, 1fr) / 0.8fr 1fr 100px;
  grid-template-areas:
    'itemName empty1 empty2'
    'empty0 empty1 empty2'
    'updatedAt empty1 empty2';
  column-gap: 13px;
  min-height: 95px;
  padding: 10px 25px;
  text-decoration: none;
  transition: all 500ms ease;

  &:nth-child(odd) {
    background: var(--element-background-odd);
  }

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  &:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-only(xs) {
    min-height: 110px;
    padding: 20px;
  }
}

.roleTableItem--grid {
  background: var(--element-background-odd);
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr;
  grid-template-areas:
    'itemName itemName'
    'updatedAt updatedAt';
  padding: 30px 30px 0 32px;

  @include media-breakpoint-only(xs) {
    padding: 10px;
  }

  .roleTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }
}

.roleTableItem--list {
  grid-template-columns: 0.6fr 1fr 100px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;

  .roleTableItemUpdatedAt {
    color: var(--main-text-color-alt);
    font-style: italic;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.roleTableItem--detail {
  display: grid;
  font-size: 14px;
  min-height: 70px;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'itemName createdAt updatedAt';
  padding: 20px 10px;

  & .itemName,
  & .roleTableItemCreatedAt,
  & .roleTableItemUpdatedAt,
  & .roleTableItemSettlorName {
    color: var(--main-text-color);
    font-size: 14px;
    padding: 5px 10px;
  }

  .roleTableItemCreatedAt {
    display: flex;
  }

  .typeIcon {
    position: relative;
    top: 3px;
  }
}
