.tenantUserIcon {
  color: var(--main-text-color);
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--main-color);
  position: absolute;
  top: 54px;
  z-index: 1;
  right: calc(50% + -46px);
}

.mobileWarning {
  margin-top: 15px;
  display: flex;
  font-size: 14px;

  svg {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
}
