@import '../../styles/mixings.scss';

.globalViewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rollBackButton {
  position: fixed;
  right: 80px;
  bottom: 30px;

  @include media-breakpoint-only(xs) {
    width: 100%;
    display: flex;
    justify-content: center;
    right: initial;
  }
}
