.noHistory {
  align-items: center;
  color: var(--main-text-color-alt);
  display: flex;
  flex: 1;
  justify-content: center;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.title {
  align-items: center;
  display: flex;

  svg {
    margin-right: 10px;
  }
}
