@import 'styles/mixings.scss';

.overlay {
  background: var(--sidemodal-overlay-color);
  opacity: 0;
  height: 100vh;
  position: fixed;
  right: -100vw;
  transition: opacity 500ms ease;
  top: 0;
  width: 100vw;
  z-index: 10;

  &.visible {
    opacity: 1;
    right: 0;
    transition: opacity 500ms ease;
  }
}

.creationModal {
  display: flex;
  background: var(--sidemodal-body-background);
  color: var(--main-text-color);
  display: grid;
  grid-template-rows: 1fr 70px;
  height: 100vh;
  max-width: 70vw;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 500ms ease-out;
  transform: translate(100vw, 0);
  width: 1000px;
  z-index: 20;

  @include media-breakpoint-only(sm) {
    max-width: 94vw;
  }

  @include media-breakpoint-only(xs) {
    display: block;
    height: auto;
    max-width: 100vw;
    min-height: 100%;
    max-height: 100%;
    overflow: auto;
    position: fixed;
    transform: translate(-100vw, 0);
  }

  &.visible {
    transform: translate(0, 0);
    transition: transform 500ms ease-out;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 30px;

  @include media-breakpoint-only(xs) {
    padding: 30px 15px;
  }
}

.modalButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;

  @include media-breakpoint-only(xs) {
    justify-content: center;
    padding-right: 0;
    padding-bottom: 100px;
    flex-wrap: wrap;
  }

  &.votingModal {
    display: none;
  }
}

.creationModalTitle {
  align-items: center;
  display: flex;
  font-family: 'Merriweather', serif;
  font-size: 30px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: right 200ms ease-out;

  @include media-breakpoint-only(xs) {
    font-size: 20px;
  }
}

.closeIcon {
  fill: var(--main-text-color);
  cursor: pointer;
  transition: fill 200ms ease;

  &:hover {
    fill: var(--main-color);
    transition: fill 200ms ease;
  }
}

.buttons {
  height: 30px;
}
