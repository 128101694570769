.assetContainer {
  border-bottom: 1px solid var(--element-background-odd);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 13px;

  &:last-child {
    border-bottom: none;
  }
}

.assetInfo {
  align-items: center;
  display: flex;
  font-size: 14px;

  .assetText {
    overflow: hidden;
    max-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.assetBenefs {
  color: var(--sub-menu-color);
  font-size: 14px;
  padding-top: 10px;
  max-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: initial;
}

.assetIcon {
  margin-right: 7px;
  position: relative;
  top: 2px;
}
