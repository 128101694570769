@import '../../styles/mixings.scss';

.messageContainer {
  align-items: center;
  background: var(--navbar-collapsed-border);
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  transition: background 200ms ease;
  margin-bottom: 10px;
  margin-top: -25px;
  width: fit-content;

  @include media-breakpoint-only(md) {
    margin-top: 0;
  }

  @include media-breakpoint-only(sm) {
    margin-top: 0;
    margin-bottom: 0;
    width: calc(100% + 40px);
    position: relative;
    top: -20px;
    left: -20px;
    border-radius: 0;
    padding-top: 26px;
    padding-left: 15px;
    padding-bottom: 26px;
    justify-content: space-between;

    &:hover {
      background: var(--navbar-collapsed-border) !important;
    }
  }

  @include media-breakpoint-only(xs) {
    margin-top: 0;
    margin-bottom: 0;
    width: calc(100% + 35px);
    position: relative;
    top: -20px;
    left: -20px;
    border-radius: 0;
    padding-top: 26px;
    padding-left: 15px;
    padding-bottom: 26px;
    justify-content: space-between;

    &:hover {
      background: var(--navbar-collapsed-border) !important;
    }
  }

  &:hover {
    background: var(--navbar-collapsed-border-hover);
    transition: background 200ms ease;
  }

  svg {
    margin-left: 10px;
  }
}

.arrowIcon {
  padding-top: 6px;
}
