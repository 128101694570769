.input {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--inputs-border-color);
  border-radius: 100px;
  color: var(--main-text-color);
  font-family: verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  transition: all 200ms ease;
  width: 100%;

  &::placeholder {
    color: var(--inputs-placeholder-color);
  }

  &:disabled {
    opacity: 0.3;
    transition: all 200ms ease;
  }

  &:focus {
    border-color: var(--main-color);
    color: var(--main-color);

    &::placeholder {
      color: var(--main-color-alt) !important;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &.error {
    border-color: var(--signin-error-color) !important;

    &::placeholder {
      color: var(--signin-error-color) !important;
    }
  }
}

:global {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__month-container {
    background-color: var(--datepicker-background);
    border-radius: 5px;
  }
}

.iphoneSelector {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--inputs-border-color);
  border-radius: 100px;
  height: 40px;
  line-height: 40px;
  width: 100%;
}

.iosInput {
  display: block;
  height: 40px;
  position: relative;
}

.iosPlaceholder {
  align-items: center;
  color: var(--inputs-placeholder-color);
  display: flex;
  height: 40px;
  font-size: 14px;
  left: 25px;
  position: absolute;
  top: 0;
}

.pickerContainer {
  position: relative;
}

.pickerIcon {
  color: var(--inputs-placeholder-color);
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
}
