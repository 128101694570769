.schedulingInfo {
}

.labelContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--sub-menu-color);
}

.icon {
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
