.changeStatusButton {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;

  &.collapsed {
    width: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
  }
}

.alertExecution {
  cursor: pointer;
  fill: var(--signin-error-color);
  opacity: 1;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.8;
    transition: opacity 200ms ease;
  }
}

.tooltipErrorsPosition {
  align-items: center;
  background-color: #191919 !important;
  display: flex;
  min-height: 45px;
  opacity: 1 !important;

  &:after {
    border-right-color: #191919 !important;
    opacity: 1 !important;
  }
}

.buttonCollapsable {
  &:not(:hover) {
    min-width: 35px;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: transparent;
    width: 35px;
    white-space: nowrap !important;

    svg {
      fill: var(--secondary-color);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .secondary & {
        fill: var(--main-text-color) !important;
      }
    }

    span {
      display: none;
    }
  }

  justify-content: flex-start;
  white-space: nowrap !important;
  min-width: fit-content;
}

.storeButton {
  svg {
    fill: var(--button-default-background) !important;
  }
}
