@import 'styles/mixings';

.modal {
  background: var(--modal-background);
  border-radius: 5px;
  bottom: auto;
  color: var(--main-text-color);
  left: 50%;
  right: auto;
  margin-right: -50%;
  max-width: 60vw;
  opacity: 0;
  padding: 30px;
  position: absolute;
  top: 30%;
  transform: translate(-50%, -100vh);
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  z-index: 100;
  outline: none;

  @include media-breakpoint-only(sm) {
    max-width: 90vw;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
  }

  @include media-breakpoint-only(xs) {
    max-width: 95vw;
    transform: translate(-50%, -50%);
    padding: 15px;
    top: 50%;
    width: 100%;
  }
}

.modalAfterOpen {
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  opacity: 1;

  @include media-breakpoint-only(md) {
    transform: translate(-50%, 0);
  }

  @include media-breakpoint-only(lg) {
    transform: translate(-50%, 0);
  }
}

.modalBeforeClose {
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  opacity: 0;

  @include media-breakpoint-only(md) {
    transform: translate(-50%, -100vh);
  }

  @include media-breakpoint-only(lg) {
    transform: translate(-50%, -100vh);
  }
}

.overlay {
  background-color: var(--modal-overlay);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 2000ms ease-in-out;
  z-index: 99;
}

.overlay--after-open {
  opacity: 1;
}

.overlay--before-close {
  opacity: 0;
}

.modalTopBar {
  display: flex;
  flex-direction: row;
}

.modalContainer {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-only(lg) {
    min-width: 560px;
  }

  @include media-breakpoint-only(md) {
    min-width: 560px;
  }

  &.large {
    @include media-breakpoint-only(lg) {
      min-width: 800px;
    }

    @include media-breakpoint-only(md) {
      min-width: 800px;
    }
  }
}

.closeButton {
  align-self: flex-end;
  cursor: pointer;
  transition: 200ms ease color;

  &:hover {
    color: var(--main-color);
    transition: 200ms ease color;
  }
}

.modalTitle {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;

  @include media-breakpoint-only(xs) {
    text-align: center;
  }
}

.modalContent {
  color: var(--main-text-color-alt);
  flex: 1;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.buttonsAlignRight {
  justify-content: flex-end;
}

.modalButtonsCustom {
  justify-content: space-evenly;

  @include media-breakpoint-only(xs) {
    flex-direction: column;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
