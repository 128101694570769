.inputContainer {
  position: relative;
}

.passwordIcon {
  position: absolute;
  top: 36px;
  cursor: pointer;
  right: 15px;
  transition: color 300ms ease;

  &:hover {
    color: var(--main-color);
    transition: color 300ms ease;
  }
}
