.container {
  display: grid;
  grid-gap: 20px;
}

.deleteButton {
  width: fit-content;
  align-self: flex-end;
}

.deleteContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.alertIcon {
  cursor: pointer;
  color: var(--signin-error-color);
}
