.missingSelection {
  color: var(--signin-error-color);
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  animation-duration: 2s;
  animation-name: messageError;
}

.containerMessageError {
  display: flex;
  justify-content: center;
}

.messageError {
  color: var(--signin-error-color);
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  max-width: 400px;
  animation-duration: 3ms;
  animation-name: messageError;
}

@keyframes messageError {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
