@import '../../styles/mixings.scss';

.statusContainer {
  align-items: center;
  color: var(--element-main-color);
  display: flex;
  font-size: 11px;
  text-transform: uppercase;

  @include media-breakpoint-only(xs) {
    margin-top: 10px;
  }
}

.onlyBullet {
  width: auto;

  .status {
    display: none;
  }

  .bullet {
    margin-right: 0;
  }
}

.bullet {
  border-radius: 100px;
  display: block;
  min-height: 12px;
  margin-right: 10px;
  min-width: 12px;
}

.dottedUnderline {
  border-bottom: 1px dashed var(--dynamicformsubtitle-color);
  width: fit-content;
}

.alternativeBullet {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin-left: 5px;
  align-self: flex-start;
}

.statusActive {
  background: var(--status-stored);
}

.statusInactive {
  background: var(--status-draft);
}

.pendingActivation {
  background: var(--status-executed);
}

.tooltipText {
  text-transform: none;
  font-style: italic;
}
