@import 'styles/mixings';

.container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: var(--element-background-hover);
    transition: background 200ms ease;
  }

  @include media-breakpoint-only(lg) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }

  @include media-breakpoint-only(md) {
    &:hover {
      .tableRowDelete {
        width: 50px;
      }
    }
  }
}

.wrapper {
  flex: 1;
  padding: 20px 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 200ms ease;

  @include media-breakpoint-only(xs) {
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.date {
  color: var(--main-text-color-alt);
  font-size: 14px;
  padding-bottom: 8px;
}

.status {
  text-transform: capitalize;
}

.spanInformation {
  margin-right: 5px;
}

.dateStored {
  color: var(--main-color);
}

.itemName {
  font-size: 20px;
  font-family: var(--mainFont);
  grid-area: itemName;
  text-transform: capitalize;
}

.tableRowDelete {
  align-items: center;
  background: var(--bg-delete-button);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--color-delete-button);
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: width 200ms ease, background 200ms ease, color 200ms ease;
  width: 0;

  &:hover {
    color: var(--main-text-color);
    background: var(--bg-delete-button-bg);
    transition: background 200ms ease, color 200ms ease;
  }

  &.showDelete {
    display: flex;
  }

  &.showSwipeDelete {
    width: 50px !important;
  }
}

.votingIcon {
  margin-top: 10px;
  min-width: initial !important;
  height: auto !important;
  padding: 3px 10px !important;
}
