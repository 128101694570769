.capitalize {
  text-transform: capitalize;
}

.dottedBorder {
  border-bottom: 1px dashed var(--main-text-color-alt);
  white-space: nowrap;
  width: fit-content;
}

.andXMore {
  display: block;
  width: fit-content;
  text-transform: none;
  white-space: nowrap;
}

.wrapper {
  display: flex;
  align-items: center;
}
