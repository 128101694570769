.dateContainer {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.recurrenceContainer {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-gap: 10px;
}
