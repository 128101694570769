.radioButtonToggle {
  color: var(--main-text-color-alt);
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 10px;
  margin-bottom: 15px;
  transition: color 200ms ease;

  &:not(.literal):hover {
    color: var(--main-color);
    transition: color 200ms ease;
  }

  svg {
    margin-right: 10px;
  }

  &.literal {
    color: var(--inputs-border-color);
  }

  &:not(.literal) {
    cursor: pointer;
  }
}

.radioButtonToggleError {
  color: var(--signin-error-color);
}

.italic {
  font-style: italic;
}

.radioButtonIcon {
  display: flex;
}
