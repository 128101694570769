.container {
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 0.35fr 1fr;
  grid-gap: 10px;
  margin-bottom: 16px;
}

.assetNameContainer {
  padding: 10px;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assetValuationContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  background: var(--primary-tooltip-background);
}

.columnDescription {
  color: var(--main-text-color-alt);
  margin-bottom: 10px;
}

.gridCell {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cellValue {
  border-bottom: 1px solid var(--main-text-color-alt);
}
