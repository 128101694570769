.container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: none;
  }
}
