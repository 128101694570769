@import '../styles/mixings.scss';

:global {
  .Toastify__toast-container {
    width: 345px !important;
  }
}

.toast {
  border-radius: 50px !important;
  background: var(--sidemodal-toast-background) !important;
  color: var(--main-text-color) !important;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

  @include media-breakpoint-only(xs) {
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    background: var(--sidemodal-toast-background) !important;
  }
}

.success {
  & .toastContentIcon {
    background: var(--status-published);
    color: var(--main-text-color);
  }
}

.warning {
  & .toastContentIcon {
    background: var(--primary-btn-main-color-1);
    color: var(--main-text-color);
  }
}

.error {
  & .toastContentIcon {
    background: var(--signin-error-color);
    color: var(--signin-error-color);
    position: relative;
    z-index: 1;

    & svg {
      width: 36px;
      height: 36px;
    }

    &:before {
      background: var(--main-text-color);
      content: '';
      width: 14px;
      height: 13px;
      z-index: -1;
      position: absolute;
    }
  }
}

.toastBody {
}

.progressBar {
  background-image: linear-gradient(
    to right,
    var(--primary-btn-main-color-1),
    var(--primary-btn-main-color-2)
  );
}

.toastContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toastContentIcon {
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.toastContentText {
  margin-left: 10px;
  font-size: 16px;
}

.closeButton {
  align-items: center;
  display: flex;
  margin-right: 10px;
}
