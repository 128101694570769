@import 'styles/mixings.scss';

.valuations {
  display: grid;
  grid-gap: 15px;
}

.actionsContainer {
  bottom: 30px;
  right: 80px;
  display: flex;
  position: fixed;
  z-index: 11;

  @include media-breakpoint-only(md) {
    right: 40px;
  }

  @include media-breakpoint-only(sm) {
    right: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-end;
    bottom: 0;
    height: 50px;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    right: 0;
    top: 30px;
  }
}

.backButton {
  margin-right: 20px;
}

.subtitle {
  display: flex;
  text-decoration: none;
  font-family: var(--subtitleFont);
  order: -1;
  color: var(--main-color);
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
  padding: 0;

  @include media-breakpoint-only(xs) {
    margin-left: 45px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 6px;
    order: 0;
  }
}

.errorMessageModal {
  color: var(--signin-error-color);
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.InputSideLabel {
  margin-right: 10px;
  color: var(--main-text-color-alt);
}

.dateInputWrapper {
  width: 250px;
}

.clearButton {
  margin-right: 32px;
}
