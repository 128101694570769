@import '../../../../styles/mixings.scss';

.ruleDisplay {
  background: var(--element-background);
  color: var(--main-text-color);
  cursor: pointer;
  border-top: 7px solid;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  transition: border-color 200ms ease;

  &.hover {
    transition: border-color 200ms ease;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.selected {
  background: var(--element-background-selected);
}

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
}

.edition {
  cursor: inherit;
}
