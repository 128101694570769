@import '../../styles/mixings.scss';

.pageTitleButton {
  border: 1px solid var(--history-bullet-inner-circle);
  border-radius: 50px;
  padding: 3px 12px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  max-width: 220px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-default-background);
    color: var(--secondary-color);
    transition: background-color 200ms ease, color 200ms ease;
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;

    &:hover {
      background-color: initial;
      color: var(--button-default-background);
    }
  }
}
