@import '../../styles/mixings.scss';

.viewModeButtons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-only(xs) {
    display: none;
  }

  label {
    color: var(--main-text-color-alt);
    margin-right: 10px;
    font-size: 14px;
  }
}

.icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all 200ms ease;
  width: 40px;
  border-radius: 100px;
  margin-left: 10px;

  &:hover {
    background: var(--navbar-background-active);
    color: var(--secondary-color);
    transition: all 200ms ease;
  }

  @include media-breakpoint-only(xs) {
    &:hover {
      color: var(--main-text-color);
    }
  }

  &.selected {
    background: rgba(216, 216, 216, 0.23);

    &:hover {
      color: var(--main-text-color);
    }
  }

  &.hideButton {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
