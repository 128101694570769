@import '../../styles/mixings.scss';

.selectedUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 20px 50px;

  @include media-breakpoint-only(sm) {
    padding: 20px;
  }

  @include media-breakpoint-only(xs) {
    align-items: flex-start;
    padding: 20px;
    flex-direction: column;
  }

  &:hover {
    background: transparent;
  }
}
