.statusVoterContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--settlor-display-border-color);
}

.voteName {
  margin-bottom: 10px;
  font-size: 14px;
}
