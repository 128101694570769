@import 'styles/mixings.scss';

.loading {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 30px;
}

.titleAndOrder {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  position: relative;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.containerFilterStatus {
  margin-right: 30px;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  position: relative;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.containerFilters {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.moreFilters {
  margin-right: 30px;
}

.order {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & > div {
    display: flex;
  }

  label {
    color: var(--main-text-color-alt);
    margin-right: 10px;
  }

  .orderBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--element-background-odd);
    border-radius: 50px;
    padding: 3px 5px 3px 15px;
    min-width: 105px;
  }

  svg {
    margin-left: 5px;
    width: 20px;
  }
}

.orderButton {
  cursor: pointer;
  margin-left: 10px;
}

.table_list {
  .tableItems {
    display: grid;
    grid-gap: 10px;
    margin-top: 15px;
  }
}

.table_grid {
  .tableItems {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @include media-breakpoint-only(xs) {
      grid-gap: 10px;
    }
  }
}

.tableDetailTitle {
  display: none;
  padding-right: 10px;
  padding-left: 10px;
}

.order {
  display: flex;
}

.table_detail {
  .order,
  .orderButton {
    display: none;
  }

  .tableItems {
    display: grid;
    grid-gap: 10px;
  }

  .tableDetailTitle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 10px;
    grid-column-gap: 5px;

    > div {
      flex: 1;
      padding: 5px 10px;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 11px;
      color: var(--element-main-color);
      cursor: pointer;

      &.selectedOrder {
        color: white;
      }
    }
  }
}

.dropdownOrder {
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 30px;
  flex-direction: column;
  background: white;
  color: black;
  border-radius: 10px;
  overflow: hidden;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease-in-out;
  min-width: 105px;
  z-index: 100;

  &.showDropdown {
    visibility: visible;
    opacity: 1;
  }

  & .orderArrow {
    display: none;
  }

  & > div {
    padding: 5px 20px;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 35px;

    &:hover {
      background: #ededed;
    }

    &.selectedOrder {
      background: var(--main-color);
      color: white;

      &:hover {
        background: var(--main-color);
      }
    }
  }
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
    align-items: flex-end;
  }
}
