@import '../../styles/mixings.scss';

$buttonHeightBig: 50px;
$buttonHeightNormal: 40px;
$buttonHeightSmall: 35px;
$buttonHeightSmallest: 22px;

.button {
  align-items: center;
  background: var(--button-default-background);
  border: 1px solid var(--button-default-border);
  border-radius: var(--button-border-radius);
  display: flex;
  color: var(--button-default-color);
  cursor: pointer;
  font-family: verdana, sans-serif;
  font-size: 16px;
  height: $buttonHeightNormal;
  justify-content: center;
  min-width: 135px;
  outline: none;
  padding: 0 30px;
  position: relative;
  transition: all 200ms ease-out, transform 1ms;
  text-decoration: none;
  width: fit-content;

  @include media-breakpoint-only(xs) {
    padding: 0 15px;
  }

  .icon {
    align-items: center;
    display: flex !important;
  }

  &:not(.circle) .icon {
    margin-left: 5px;
  }

  &.iconLeft {
    .icon {
      margin-left: 0;
      margin-right: 10px;
      order: -1;
    }

    &.small {
      padding-left: 20px;
    }

    &.smallest {
      padding-left: 10px;
    }
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: var(--secondary-color-hover);
    background-color: var(--button-default-background-hover);
    border-color: var(--button-default-background-hover);
    transition: all 200ms ease-out, transform 1ms;
  }

  &:focus:not(:disabled) {
    transform: translateY(-2px);
    transition: all 200ms ease-out;
  }

  &:active {
    transform: translate(0, 1px);
    transition: all 200ms ease-out, transform 1ms;
  }

  &.primary {
    background-image: linear-gradient(
      to right,
      var(--primary-btn-main-color-1),
      var(--primary-btn-main-color-2),
      var(--primary-btn-alt-color-1),
      var(--primary-btn-alt-color-2)
    );
    background-size: 300%;
    background-position: left center;
    border: none;
    color: var(--main-text-color);
    transition: all 200ms ease-out, transform 1ms;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-position: 100% center;
      box-shadow: 0 10px 40px 0 var(--button-primary-box-shadow-hover);
      color: var(--main-text-color);
      transition: all 200ms ease-out;
    }
  }

  &.secondary {
    background-color: var(--secondary-color);
    border: none;
    color: var(--main-text-color);

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: var(--secondary-color-hover);
      color: var(--main-text-color);
    }
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid var(--main-text-color);
    color: var(--main-text-color);

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-image: linear-gradient(
        to right,
        var(--primary-btn-main-color-1),
        var(--primary-btn-main-color-2),
        var(--primary-btn-alt-color-1),
        var(--primary-btn-alt-color-2)
      );
      color: var(--main-text-color);
      border: 0.8px solid var(--bold-color);
    }
  }

  &.big {
    height: $buttonHeightBig;
  }

  &.small {
    height: $buttonHeightSmall;
    font-size: 14px;
  }

  &.smallest {
    height: $buttonHeightSmallest;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.fullWidth {
    width: 100%;
  }

  &:disabled {
    cursor: auto;
  }

  &.isLoading {
    padding-right: 35px;
    transition: all 200ms ease-out, transform 1ms;
  }

  &.circle {
    padding: 0;
    min-width: auto;
    width: $buttonHeightNormal;

    &.big {
      width: $buttonHeightBig;
    }

    &.small {
      width: $buttonHeightSmall;
    }

    &.smallest {
      width: $buttonHeightSmallest;
    }
  }

  &.error {
    border: 2px solid var(--signin-error-color) !important;
  }
}

label {
  cursor: pointer;
}

.loadingRing {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 2px solid var(--button-loading-ring);
  border-color: var(--button-loading-ring) transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  right: 10px;
  height: 20px;
  width: 20px;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
