@import 'styles/mixings.scss';

.errorBox {
  color: var(--signin-error-color);
  font-size: 16px;
  margin-bottom: 30px;
}

.errorBox {
  color: var(--signin-error-color);
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
}

.successBox {
  color: var(--main-text-color);
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
}

.container,
.success {
  min-height: 215px;
}

.form,
.success {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.forgotFormForm {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.actionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.backButton {
  margin-top: 15px;
}
